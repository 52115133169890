import React from "react";
import { useWarnContext } from "./Warned";

export const WarnedPlaceholder: React.FC = ({ children }) => {
  const { warnings, showContent } = useWarnContext();
  if(warnings.block.length > 0 || warnings.unknown.length === 0 || showContent) {
    return <React.Fragment />;
  }
  return <React.Fragment>
    { children }
  </React.Fragment>;
}
