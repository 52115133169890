import React, { useState } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Input,
} from '@chakra-ui/react';

export const LoginForm: React.FC<{
  onEmailUpdate: (t: string) => void;
  onPasswordUpdate: (t: string) => void;
}> = ({ onEmailUpdate, onPasswordUpdate }) => {

  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");

  return (
    <Box>
      <FormControl id="email" my="2">
        <FormLabel>Email Address</FormLabel>
        <Input
          type="email"
          value={email}
          onChange={e => {
            const email = e.target.value;
            onEmailUpdate(email);
            setEmail(email);
          }}
        />
      </FormControl>
      <FormControl id="password" my="2">
        <FormLabel>Password</FormLabel>
        <Input
          type="password"
          value={pass}
          onChange={e => {
            const password = e.target.value;
            onPasswordUpdate(password);
            setPass(password);
          }}
        />
      </FormControl>
    </Box>
  );
};
