import { useDisclosure } from "@chakra-ui/react";
import React, { useContext, useMemo, useState } from "react";
import { Warning } from "./Warning";
import { WarningsDrawer } from "./WarningsDrawer";
import { meetsWarnMatch } from "./WarnSettings";
import { useWarnSettings } from "./WarnSettingsContext";

export interface ParsedWarnings {
  /**
   * The entire list of warnings for this content.
   */
  all: Warning[];

  /**
   * The list of warnings that the user has not marked a preference for.
   */
  unknown: Warning[];

  /**
   * The list of warnings on this content that the user has configured to always block.
   *
   * Users may set their preferences to just block content matching specific
   * filters (with a minimal interface to adjust settings, but shouldn't be prompted to review).
   */
  block: Warning[];

  /**
   * The list of warnings on this content that the user has marked as acceptable to show.
   *
   * Check that {@link unknown} and {@link block} are empty before going ahead and showing the image.
   */
  show: Warning[];
}

export interface WarnContextData {
  /**
   * A parsed version of the warnings, using the user's settings.
   */
  warnings: ParsedWarnings;

  /**
   * If the user has overridden the warning and decided to show the content anyways.
   */
  showContent: boolean;

  /**
   * If the UI for updating warnings is open.
   */
  isOpen: boolean;

  /**
   * Open the UI to select preferences.
   */
  openUi: () => void;

  /**
   * Close the warning UI.
   */
  closeUi: () => void;

  /**
   * Make the content visible (1-off)
   */
  enableContent: () => void;
}

const WarnContext = React.createContext<WarnContextData>({
  warnings: {
    all: [],
    block: [],
    show: [],
    unknown: [],
  },
  showContent: false,
  isOpen: false,
  openUi: () => {},
  closeUi: () => {},
  enableContent: () => {},
});

export const useWarnContext = () => useContext(WarnContext);

/**
 * Basic wrapper/context for content that users should be warned about.
 */
export const Warned: React.FC<{
  warnings: Warning[];
}> = ({ warnings, children }) => {
  const { settings } = useWarnSettings();
  const [showContent, setShowContent] = useState(false);
  const parsed = useMemo<ParsedWarnings>(() => {
    const blocking = settings.block ?? [];
    const showing = settings.show ?? [];
    const unknown: Warning[] = [];
    const block: Warning[] = [];
    const show: Warning[] = [];
    for (const warning of warnings) {
      if(settings.showAlways === true) {
        show.push(warning);
        continue;
      }
      for (const blockSetting of blocking) {
        if(meetsWarnMatch(blockSetting, warning)) {
          block.push(warning);
          continue;
        }
      }
      for (const showSetting of showing) {
        if(meetsWarnMatch(showSetting, warning)) {
          show.push(warning);
          continue;
        }
      }
      unknown.push(warning);
    }
    return { all: warnings, block, show, unknown };
  }, [warnings, settings]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const context: WarnContextData = {
    warnings: parsed,
    showContent,
    isOpen,
    openUi: onOpen,
    closeUi: onClose,
    enableContent: () => setShowContent(true),
  };
  return (
    <WarnContext.Provider value={context}>
      { children }
      <WarningsDrawer />
    </WarnContext.Provider>
  );
};
