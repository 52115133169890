import {
  array,
  boolean,
  mixed,
  number,
  object,
  SchemaOf,
  string,
} from 'yup';
import { AttachedWarningStrengthType } from '../../../generated/graphql';
import { Warning } from './Warning';

enum WarnMatchType {
  /**
   * Preference matches the exact warning and strength given.
   */
  WARNING_AND_STRENGTH,

  /**
   * Preference matches any strength for the given warning.
   */
  ANY_STRENGTH_OF_WARNING,
}

export interface WarnMatch {
  mode: WarnMatchType;
  warning: string;
  strength?: AttachedWarningStrengthType;
}

export interface WarnSettings {
  /**
   * Global setting to show all content no matter what.
   * Overrides all other settings.
   */
  showAlways?: boolean;

  /**
   * Content that should always be blocked if matched.
   */
  block?: WarnMatch[];

  /**
   * Content that can be shown if matched.
   */
  show?: WarnMatch[];
};

const warnMatch: SchemaOf<WarnMatch> = object({
  mode: mixed().oneOf([
    WarnMatchType.WARNING_AND_STRENGTH,
    WarnMatchType.ANY_STRENGTH_OF_WARNING,
  ]),
  warning: string().required(),
  strength: mixed().when('mode', {
    is: WarnMatchType.WARNING_AND_STRENGTH,
    then: mixed().oneOf([
      AttachedWarningStrengthType.EMPTY,
      AttachedWarningStrengthType.MODERATE,
      AttachedWarningStrengthType.SOLID,
      AttachedWarningStrengthType.STRONG,
      AttachedWarningStrengthType.WEAK,
    ]),
    otherwise: number().nullable(),
  }),
});

const warnSettingsSchema: SchemaOf<WarnSettings> = object({
  showAlways: boolean().optional(),
  block: array().of(warnMatch).optional(),
  show: array().of(warnMatch).optional(),
});

export function loadWarnSettings(): WarnSettings {
  const raw = localStorage.getItem("warn_settings");
  if(!raw) {
    return {};
  }
  const loaded = JSON.parse(raw);
  const validated: WarnSettings = warnSettingsSchema.validateSync(loaded);
  return validated;
}

export function saveWarnSettings(settings: WarnSettings): void {
  const validated: WarnSettings = warnSettingsSchema.validateSync(settings);
  localStorage.setItem("warn_settings", JSON.stringify(validated));
}

export function meetsWarnMatch(match: WarnMatch, content: Warning) {
  const mode = match.mode;
  if(mode === WarnMatchType.WARNING_AND_STRENGTH) {
    return match.warning === content.warning?.fullIdx && match.strength === content.strength;
  } else if(mode === WarnMatchType.ANY_STRENGTH_OF_WARNING) {
    return match.warning === content.warning?.fullIdx;
  }
  throw new ReferenceError(`Unknown match type: ${mode}`);
}
