import React from "react";
import { Skeleton, Text } from "@chakra-ui/react";
import { Redacted } from "./Redacted";

const platformNames: Record<string, string> = {
  facebook: "Facebook",
  instagram: "Instagram",
  twitter: "Twitter",
  linkedin: "LinkedIn",
  website: "Independent Website",
};

export const Platform: React.FC<{
  loading?: boolean;
  platform?: string | null | undefined;
  variant?: string,
}> = ({ loading, platform, variant }) => {
  if(loading === true) {
    return <Skeleton width="8em" />;
  }
  if(typeof platform !== "string") {
    return <Redacted />;
  }
  if(Object.keys(platformNames).includes(platform)) {
    return <Text variant={variant}>{ platformNames[platform] }</Text>;
  }
  return (
    <Text
      variant={variant}
      color={"orange.500"}
    >
      Unknown Platform
    </Text>
  );
}
