import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import * as React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { createClient, Provider } from "urql";
import { App } from "./App";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";
import { theme } from "./theme";
import { getApiUrl } from "./get-api";
import posthog from "posthog-js";

posthog.init(
  'phc_im1v9EbVugfHARr5L45FtD4TvoQITLUY8O1GTCjGVES',
  {
    api_host: 'https://app.posthog.com',
  },
);

const client = createClient({
  url: getApiUrl(window.location.host, true, undefined),
  fetchOptions: {
    credentials: "include",
  }
});

ReactDOM.render(
  <React.StrictMode>
    <Provider value={client}>
      <ChakraProvider theme={theme}>
        <BrowserRouter>
          <ColorModeScript />
          <App />
        </BrowserRouter>
      </ChakraProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root"),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
