import {
  Badge,
  Center,
  Link,
  Skeleton,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Redacted } from "../render/Redacted";

export const LoadingCell = () => (
  <Skeleton startColor="accent.1000" endColor="accent.900" height="10px" />
);

export const RedactedCell = () => (
  <Center>
    <Badge colorScheme="red">Redacted</Badge>
  </Center>
);

export const FullRow: React.FC = ({ children }) => (
  <Tr>
    <Td colSpan={4}>
      { children }
    </Td>
  </Tr>
);

export const TheListRow: React.FC<{
  id: string,
  name: null | string,
  relevance: null | string[],
  views: string,
  behavior: null | (null | { label: string | null | undefined, section: string | null | undefined })[],
}> = ({ id, name, relevance, views, behavior }) => {
  if(!name && !relevance) {
    return <FullRow><RedactedCell /></FullRow>;
  }
  return (
    <Tr>
      <Td>
        <Link as={RouterLink} to={`/contact/${id}/`}>
          { name !== null ? name : (<RedactedCell />) }
        </Link>
      </Td>
      <Td textAlign="center">{ views }</Td>
      <Td>{ relevance !== null ? relevance.join(", ") : (<RedactedCell />)}</Td>
      <Td>{ behavior === null || behavior.length === 0 ? null : (
        <Stack>
          { behavior.map(b => {
            if(b === null || typeof b.label !== "string") {
              return <Redacted />;
            } else if(b.section) {
              return (
                <Link as={RouterLink} to={`/contact/${id}/#${b.section}`}>{b.label}</Link>
              )
            } else {
              return (
                <Text>{b.label}</Text>
              );
            }
          })}
        </Stack>
      )}</Td>
    </Tr>
  )
}

export const TheListTable: React.FC = ({ children }) => (
  <Table variant="simple" colorScheme="white">
    <Thead>
      <Tr>
        <Th textAlign="center">Name</Th>
        <Th textAlign="center">Views</Th>
        <Th textAlign="center">Position</Th>
        <Th textAlign="center">Behavior</Th>
      </Tr>
    </Thead>
    <Tbody>
      { children }
    </Tbody>
  </Table>
);
