import React from "react";
import { Skeleton, Text } from "@chakra-ui/react";
import { Redacted } from "./Redacted";
import {
  Maybe,
  PostSubject,
  Subject as ApiSubject,
} from "../../generated/graphql";

type SubjectData =
  Pick<PostSubject, 'matchStrength' | 'notes'>
  & { subject?: Maybe<Pick<ApiSubject, 'id' | 'fullIdx' | 'name'>> };

export const Subject: React.FC<{
  loading?: boolean;
  subject?: SubjectData,
  variant?: string,
}> = ({ loading, subject, variant }) => {
  if(loading === true) {
    return <Skeleton width="8em" />;
  }
  const idx = subject?.subject?.fullIdx;
  const name = subject?.subject?.name;
  if(typeof idx !== "string" || typeof name !== "string") {
    return <Redacted />;
  }
  return (
    <Text variant={variant}>
      {idx} {name} ({subject?.matchStrength})
    </Text>
  );
};
