import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { useWarnContext } from "./Warned";
import { Warning } from "./Warning";

const WarningEntry: React.FC<{
  warning: Warning;
}> = ({ warning }) => {
  return (
    <Box maxW="s" borderWidth="1px" borderRadius="lg" overflow="hidden" mx={2} my={4} py={2} px={4}>
      <Stack direction="row">
        <Text
          fontWeight="bold"
          textTransform="uppercase"
          fontSize="sm"
          letterSpacing="wide"
        >
          { warning.warning?.fullIdx }
        </Text>
        <Text
          fontWeight="bold"
          textTransform="capitalize"
          fontSize="sm"
          letterSpacing="wide"
        >
          {warning.strength }
        </Text>
      </Stack>
      <Text
        fontWeight="bold"
        fontSize="lg"
      >
        { warning.warning?.name }
      </Text>
    </Box>
  );
};

export const WarningsDrawer: React.FC = () => {
  const { warnings, isOpen, closeUi, enableContent } = useWarnContext();
  return (
    <Drawer
      isOpen={isOpen}
      placement="bottom"
      size="lg"
      onClose={closeUi}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Content Warnings</DrawerHeader>
        <DrawerBody>
          <Text fontSize="lg">
            This content has the following warnings:
          </Text>
          { warnings.all.map(w => <WarningEntry key={w.id} warning={w} />) }
        </DrawerBody>
        <DrawerFooter borderTopWidth="1px">
          <Button variant="outline" mr={3} onClick={() => closeUi()}>
            Close
          </Button>
          <Button colorScheme="orange" onClick={() => {
            enableContent();
            closeUi();
          }}>
            Show Content
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
};
