import React from "react";
import { Link, Skeleton, Text } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { Redacted } from "./Redacted";

interface AuthorValue {
  id: string;
  name?: string | null | undefined;
}

export const Author: React.FC<{
  loading?: boolean;
  author?: AuthorValue | null | undefined;
  variant?: string,
}> = ({ loading, author, variant }) => {
  if(loading === true) {
    return <Skeleton width="8em" />;
  }
  if(!author || !author.name) {
    return <Redacted />;
  }
  return (
    <Text variant={variant}>
      <Link as={RouterLink} to={`/contact/${author.id}`}>{ author.name }</Link>
    </Text>
  );
}
