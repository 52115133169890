import { extendTheme } from "@chakra-ui/react";
import { accent, action, secondary } from "./colors";
import { Link } from "./components/Link";
import { Text } from "./components/Text";

// https://coolors.co/5a716a-84b082-9fbbf9-815355-59364e

export const theme = extendTheme({
  colors: {
    accent,
    action,
    secondary,
  },
  components: {
    Link,
    Text,
  },
  layerStyles: {
    "info-why": {
      borderRadius: "md",
      bg: "action.300",
      color: "action.900",
      ".chakra-ui-dark &": {
        bg: "action.900",
        color: "action.100",
      },
    },
    "info-research": {
      borderRadius: "md",
      bg: "secondary.500",
      color: "secondary.900",
      ".chakra-ui-dark &": {
        bg: "secondary.700",
        color: "secondary.100",
      },
    },
    "the-list-bg": {
      borderRadius: "md",
      bg: "accent.200",
      color: "accent.1000",
      ".chakra-ui-dark &": {
        bg: "accent.800",
        color: "accent.50",
      },
    },
    "info-promo": {
      borderRadius: "md",
      bg: "secondary.500",
      color: "secondary.900",
      ".chakra-ui-dark &": {
        bg: "secondary.700",
        color: "secondary.100",
      },
    },
  },
});
