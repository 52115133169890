import React from "react";
import {
  Box,
  Text,
  Heading,
  VStack,
  Flex,
  Center,
  Container,
  Link,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { ColorModeSwitcher } from "../ColorModeSwitcher";
import { ContactContextType, ContactSourceContextType } from "../generated/graphql";
import { HomepagePromoListLink } from "../homepage/ListLink";
import { TheList } from "../components/list/TheList";

export const SwimmingList: React.FC = () => {
  return (
    <React.Fragment>
    <Flex p={3}>
      <Box flex="1">
        <Center>
          <VStack textAlign="center">
            <Heading as="h1" size="3xl">TTT</Heading>
            <Text fontSize="md">Transphobe/TERF Tracker</Text>
            <Text fontSize="2xl">Tracking transphobes in swimming</Text>
          </VStack>
        </Center>
      </Box>
      <ColorModeSwitcher justifySelf="flex-end" />
    </Flex>
    <HomepagePromoListLink />
    <TheList
      contexts={[
        ContactContextType.ALL_SPORTS,
        ContactContextType.SWIMMING,
        ContactContextType.ANY_SPORT,
      ]}
      sourceContexts={[
        ContactSourceContextType.SWIMMING,
      ]}
    />
    <Container maxW="container.lg" mt="4" mb="2">
      <Link as={RouterLink} to="/login/">Login</Link>
    </Container>
  </React.Fragment>
  );
}
