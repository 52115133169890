function automaticallyDetected(hostname: string = window.location.host): string {
  const devMode = hostname.indexOf("localhost") !== -1;
  if(devMode) {
    return "http://localhost:4000/api/graphql";
  }
  else {
    return `https://api.${hostname}/api/graphql`;
  }
}

function userProvided() {
  const params = new URLSearchParams(window.location.search);
  const providedHost = params.get("host");
  if(providedHost !== null) {
    const host = providedHost;
    const local = host.indexOf("localhost") !== -1;
    const secure = params.has("https") || !local;
    const port = params.has("port")
      ? parseInt(params.get("port") ?? "80", 10)
      : local
        ? 3000
        : 80;
    return `http${secure ? "s" : ""}://${host}${port !== 80 ? `:${port}` : ""}/api/graphql`
  } else{
    return null;
  }
}

export function getApiUrl(
  hostname: string = window.location.host,
  allowUserProvided: boolean = true,
  override?: string,
) {
  if(override) {
    return override;
  }
  if(allowUserProvided) {
    const user = userProvided();
    if(user !== null) {
      return user;
    }
  }
  return automaticallyDetected(hostname);
}
