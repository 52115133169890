export const action = {
  100: "#D9E3FD",
  200: "#C6D7FB",
  300: "#B2C7FA",
  400: "#a3bcf9",
  500: "#9FB9F9", // (Theme) Like this for links
  600: "#8CAEF8",
  700: "#6593F6",
  800: "#3F78F3",
  900: "#0F53E6",
};

export const secondary = {
  100: "#DDE3E2",
  200: "#C7D1CE",
  300: "#B0BFBA",
  400: "#99ADA6",
  500: "#829B92",
  600: "#6D887E",
  700: "#5A716A", // (Theme) Good background color
  800: "#495B55",
  900: "#36443F",
}

export const accent = {
  25: "#F0E5EC",
  50: "#E9D8E3",
  75: "#E1CBD9",
  100: "#D2B1C6",
  200: "#C397B2",
  300: "#B57D9F",
  400: "#A6648C",
  500: "#8E5277",
  600: "#754362",
  700: "#59364E", // (Theme)
  800: "#3F2737",
  900: "#261721",
  1000: "#1A0F16",
}
