import * as React from "react";
import { Route, Routes } from "react-router-dom";
import { Homepage } from "./homepage/Homepage";
import { Report } from "./report/Report";
import { LoginPage } from "./auth/LoginPage";
import { ContactPage } from "./contact/ContactPage";
import { PostPage } from "./post/PostPage";
import { CyclingList } from "./lists/CyclingList";
import { SwimmingList } from "./lists/SwimmingList";

export const App = () => (
  <Routes>
    <Route path="/report" element={<Report />} />
    <Route path="/contact/:contactId" element={<ContactPage />} />
    <Route path="/item/:postId" element={<PostPage />} />
    <Route path="/" element={<Homepage />} />
    <Route path="/login" element={<LoginPage />} />
    <Route path="/cycling" element={<CyclingList />} />
    <Route path="/swimming" element={<SwimmingList />} />
  </Routes>
);
