import {
  Center,
  Container,
  Flex,
  Link,
  Spacer,
  Stack,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { useParams, Link as RouterLink } from "react-router-dom";
import { ColorModeSwitcher } from "../ColorModeSwitcher";
import { Platform } from "../components/render/Platform";
import { PostType } from "../components/render/PostType";
import { Author } from "../components/render/Author";
import {
  Scalars,
  useGetPostQuery,
} from "../generated/graphql";
import { WarnedImage } from "../components/warned/WarnedImage";

export const PostPage: React.FC<{}> = () => {
  const params = useParams();
  const postId = params.postId as Scalars["ID"];
  const [{ fetching, data, error }] = useGetPostQuery({
    variables: {
      id: postId,
    },
  });
  const post = data?.post;
  const loading = fetching || !!error || !post;
  return (
    <React.Fragment>
      <Flex mt={1} px={4}>
        <Text p={2} fontSize="lg">
          <Link as={RouterLink} to="/">ttt.lgbt</Link>
        </Text>
        <Spacer />
        <ColorModeSwitcher justifySelf="flex-end" />
      </Flex>
      <Container maxW="container.sm" centerContent mb={4}>
        <Stack>
          <Text size="sm">Item</Text>
          <Center>
            <Stack>
              <Stack direction="row">
                <PostType loading={loading} type={post?.postType} variant="pageTitle" />
                <Text variant="pageTitle">on</Text>
                <Platform loading={loading} platform={post?.platform} variant="pageTitle" />
              </Stack>
              <Stack direction="row">
                <Text variant="pageTitle">by</Text>
                <Author loading={loading} author={post?.author} variant="pageTitle" />
              </Stack>
            </Stack>
          </Center>
        </Stack>
      </Container>
      <Container maxW="container.lg">
        {
          post?.saved?.map(saved => {
            return (
              <WarnedImage
                warnings={saved.warnings ?? []}
                src={
                  saved.complete?.url ??
                  saved.censoredNames?.url ??
                  saved.censoredContent?.url ??
                  saved.censoredNamesAndContent?.url}
                backupUrl = {
                  saved.censoredContent?.url ?? saved.censoredNamesAndContent?.url
                }
              />
            )
          })
        }
      </Container>
    </React.Fragment>
  );
};
