import React from "react";
import { Skeleton, Text } from "@chakra-ui/react";
import { PostPostTypeType } from "../../generated/graphql";
import { Redacted } from "./Redacted";

const typeNames: Record<PostPostTypeType, string> = {
  comment: "Comment",
  fundraiser: "Fundraiser",
  fundraiser_donate: "Donation to Fundraiser",
  fundraiser_share: "Shared Fundraiser",
  legal_filing: "Submitted Legal Document",
  legal_testimony: "Testified",
  petition: "Petition",
  petition_share: "Shared Petition",
  petition_sign: "Signed Petition",
  share: "Share",
  share_commentary: "Shared (with message)",
  social: "Social Media Post",
};

export const PostType: React.FC<{
  loading?: boolean;
  type?: PostPostTypeType | null | undefined;
  variant?: string,
}> = ({ loading, type, variant }) => {
  if(loading === true) {
    return <Skeleton width="8em" />;
  }
  if(typeof type !== "string") {
    return <Redacted />;
  }
  if(Object.keys(typeNames).includes(type)) {
    return <Text variant={variant}>{ typeNames[type] }</Text>;
  }
  return (
    <Text
      variant={variant}
      color={"orange.500"}
    >
      Unknown Content Type
    </Text>
  );
}
