import {
  Skeleton,
  Text,
} from "@chakra-ui/react";
import React from "react";
import {
  Impact as ApiImpact,
  Maybe,
  PostImpact,
} from "../../generated/graphql";

type ImpactData = Pick<PostImpact, 'id' | 'motive' | 'conviction' | 'impactExtent' | 'notes'>
& { impact?: Maybe<(Pick<ApiImpact, 'id' | 'fullIdx' | 'name'>
  & { abstract?: Maybe<Pick<ApiImpact, 'id' | 'fullIdx' | 'name'>> })> };

export const Impact: React.FC<{
  loading?: boolean;
  impact?: ImpactData;
  variant?: string;
}> = ({ loading, impact, variant }) => {
  if(loading === true) {
    return <Skeleton width="8em" />;
  }
  const detailImpactIdx = impact?.impact?.fullIdx;
  const detailName = impact?.impact?.name;
  const abstractIdx = impact?.impact?.abstract?.fullIdx;
  const abstractName = impact?.impact?.abstract?.name;
  if(detailImpactIdx && (!abstractIdx || detailImpactIdx === abstractIdx)) {
    return (
      <Text variant={variant}>
        {impact.impactExtent} {detailImpactIdx} {detailName} ({impact.conviction} {impact.motive})
      </Text>
    );
  }
  return (
    <Text variant={variant}>
      {impact?.impactExtent} {abstractIdx} {abstractName} - {detailImpactIdx} {detailName} ({impact?.conviction} {impact?.motive})
    </Text>
  )
}
