import {
  Box,
  Heading,
  Text,
} from "@chakra-ui/react";
import React from "react";

export const TheListHeader = () => (
  <Box mt={2} mb={2} mx={5} p={2}>
    <Heading as="h3" size="lg" id="the-list" mb={2}>The List</Heading>
    <Text my={2}>
      The following list contains individuals, groups, and organizations
      that have acted in a transphobic manner in the cycling world.
    </Text>
    <Text my={2}>
      Some posts have been fully archived, and are referenced below under 'behavior'.
    </Text>
    <Text my={2}>
      Names are redacted if there is still more data to be archived,
      or if the contact would benefit from publicity
      (such as prolific transphobes who desire a larger audience).
    </Text>
  </Box>
)
