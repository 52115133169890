import { mode } from "@chakra-ui/theme-tools";

const titleSuperscript = {
  fontWeight: "bold",
  fontSize: "sm",
  letterSpacing: "wide",
};

const pageTitle = {
  fontSize: "xl",
};

export const Text = {
  variants: {
    titleSuperscript,
    titleSuperscriptUppercase: {
      ...titleSuperscript,
      textTransform: "uppercase",
    },
    titleSuperscriptType: (props: any) => ({
      ...titleSuperscript,
      textTransform: "capitalize",
      color: mode("action.700", "action.500")(props),
    }),
    titleSuperscriptImpact: (props: any) => ({
      ...titleSuperscript,
      textTransform: "capitalize",
      color: mode("accent.700", "accent.400")(props),
    }),
    pageTitle,
  },
};
