import React, { useContext, useState } from "react";
import { loadWarnSettings, saveWarnSettings, WarnSettings } from "./WarnSettings";

interface WarnSettingsContext {
  settings: Readonly<WarnSettings>;
  updateWarnSettings: (settings: WarnSettings) => void;
}

const warnSettingsContext = React.createContext<WarnSettingsContext>({
  settings: {},
  updateWarnSettings: () => {},
});

export const WarnSettingsHandler: React.FC = ({ children }) => {
  const [settings, setSettings] = useState<WarnSettings>(loadWarnSettings());

  function updateWarnSettings(settings: WarnSettings): void {
    saveWarnSettings(settings);
    setSettings(settings);
  }

  const ctx: WarnSettingsContext = {
    settings,
    updateWarnSettings,
  };
  return (
    <warnSettingsContext.Provider value={ctx}>
      { children }
    </warnSettingsContext.Provider>
  );
};

export const useWarnSettings = () => useContext(warnSettingsContext);
