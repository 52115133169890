import React from "react";
import { useWarnContext } from "./Warned";

export const WarnedContent: React.FC = ({ children }) => {
  const { warnings, showContent } = useWarnContext();
  if(showContent || (warnings.block.length === 0 && warnings.unknown.length === 0)) {
    return (
      <React.Fragment>
        { children }
      </React.Fragment>
    );
  } else {
    return <React.Fragment />;
  }
};
