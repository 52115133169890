import React from "react";
import {
  Badge,
  Box,
  Button,
  Center,
  chakra,
  Container,
  Flex,
  Heading,
  Link,
  Spacer,
  Stack,
  Text,
} from "@chakra-ui/react";
import { DocumentRenderer } from "@keystone-next/document-renderer";
import { useParams, Link as RouterLink } from "react-router-dom";
import { Person } from "schema-dts";
import { JsonLd } from "react-schemaorg";
import { ColorModeSwitcher } from "../ColorModeSwitcher";
import { ContactContactTypeType, Scalars, useGetContactQuery } from "../generated/graphql";
import { LoadingCell } from "../components/list/TheListTable";
import { WarnedImage } from "../components/warned/WarnedImage";
import { PostType } from "../components/render/PostType";
import { Platform } from "../components/render/Platform";
import { Subject } from "../components/render/Subject";
import { Impact } from "../components/render/Impact";

export const ContactPage: React.FC = () => {
  const params = useParams();
  const contactId = params.contactId as Scalars["ID"];
  const [{ fetching, data, error }] = useGetContactQuery({
    variables: {
      id: contactId,
    },
  });
  const contact = data?.contact;
  const loading = fetching || error || !contact;
  return (
    <React.Fragment>
      <Flex mt={1} px={4}>
        <Text p={2} fontSize="lg">
          <Link as={RouterLink} to="/">ttt.lgbt</Link>
        </Text>
        <Spacer />
        <ColorModeSwitcher justifySelf="flex-end" />
      </Flex>
      <Container maxW="container.sm" centerContent mb={4}>
        <Stack>
          <Text size="sm">Contact</Text>
          <Center>
              { loading && <LoadingCell /> }
              { !loading && (contact && contact.name
                  ? <Text fontSize="xl">{ contact.name }</Text>
                  : <Badge colorScheme="red">Redacted</Badge>)
              }
          </Center>
        </Stack>
      </Container>
      <Container maxW="container.lg">
        {
          contact?.behavior && contact.behavior
            .filter(b => typeof b.profileSection === "string" && b.profileSection.length > 0)
            .map(b => {
              return (
                <Box key={b.id} id={b.profileSection ?? undefined}>
                  <Heading
                    as="h2"
                    size="md"
                  >
                    { b.summary }
                  </Heading>
                  {(b?.content ?? []).map(content => {
                    const doc = content.summary?.find(summary => summary.content?.document)?.content?.document;
                    const subjects = content.subjects ?? [];
                    const impacts = content.impact ?? [];
                    return (
                      <Box key={content.id} p={4} display={{ md: 'flex' }}>
                        <Box flexShrink={0}>
                          <WarnedImage
                            warnings={content.preview?.warnings ?? []}
                            borderRadius="lg"
                            width={{ base: "full", md: 40 }}
                            src={content.preview?.default?.url }
                          />
                        </Box>
                        <Box mt={{ base: 4, md: 0 }} ml={{ md: 6 }}>
                          <Stack direction="row">
                            <PostType variant="titleSuperscriptType" type={content.postType} />
                            <Text variant="titleSuperscript">on</Text>
                            <Platform variant="titleSuperscriptType" platform={content.platform} />
                          </Stack>
                          { subjects.length > 0 &&
                            <Stack direction="row">
                              <Text variant="titleSuperscript">Subject:</Text>
                              {
                                subjects.map(sub => <Subject subject={sub} variant="titleSuperscriptType" />)
                              }
                            </Stack>
                          }
                          { doc && <DocumentRenderer document={doc} renderers={{
                            inline: {
                              relationship({ relationship, data }) {
                                if(relationship === "contact") {
                                  if(data === null || data.data === undefined) {
                                    return <chakra.span>[unknown author]</chakra.span>;
                                  } else {
                                    return <Link as={RouterLink} to={`/contact/${data.data.id}/`}>{data.data.name}</Link>;
                                  }
                                }
                                return null;
                              },
                            },
                          }} />}
                          { impacts.length > 0 &&
                            <Stack mt={3}>
                              <Text variant="titleSuperscriptImpact" textTransform="uppercase">Impact</Text>
                              {
                                impacts.map(impact => <Impact impact={impact} variant="titleSuperscriptImpact" />)
                              }
                            </Stack>
                          }
                          <Button as={RouterLink} size="sm" mt={1} to={`/item/${content.id}/`}>
                            Post Details
                          </Button>
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              );
            })
        }
      </Container>
      { contact
        && contact.contactType === ContactContactTypeType.INDIVIDUAL
        && <JsonLd<Person>
          item={{
            "@context": "https://schema.org",
            "@type": "Person",
            name: contact.name ?? undefined,
          }}
        />
      }
    </React.Fragment>
  );
};
