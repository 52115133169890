import {
  Button,
  Container,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { LoginForm } from "../components/LoginForm";
import { useLoginMutation } from "../generated/graphql";

export const LoginPage: React.FC<{}> = () => {
  const navigate = useNavigate();
  const [, login] = useLoginMutation();

  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");

  const handleLogin = () => {
    login({ email, password: pass }).then(res => {
      if(res.error) {
        alert(res.error);
        return;
      }
      const data = res.data?.authenticateUserWithPassword;
      if(!data) {
        alert("Bad response.");
        return;
      }
      if(data.__typename === "UserAuthenticationWithPasswordFailure") {
        alert(data.message);
        return;
      }
      if(data.__typename === "UserAuthenticationWithPasswordSuccess") {
        const session = data.sessionToken;
        localStorage.setItem("session", session);
      }
      navigate("/");
    });
  };

  return (
    <Container maxWidth="container.lg">
      <LoginForm
        onEmailUpdate={setEmail}
        onPasswordUpdate={setPass}
      />
      <Button onClick={handleLogin}>
        Login
      </Button>
    </Container>
  );
};
