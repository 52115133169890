import React, { Fragment } from "react";
import {
  Box,
  Center,
  Container,
  Heading,
} from "@chakra-ui/react";

export const Report = () => {
  return (
    <Fragment>
      <Container maxW="container.lg">
        <Box>
          <Center>
            <Heading as="h2" size="lg">Report Transphobic Content</Heading>
          </Center>
        </Box>
      </Container>
    </Fragment>
  );
};
