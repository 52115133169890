import React from "react";
import {
  ContactContextType,
  ContactPublishedListType,
  ContactSourceContextType,
  useListQuery,
} from "../../generated/graphql";
import { TheListContainer } from "./TheListContainer";
import { TheListHeader } from "./TheListHeader";
import { FullRow, LoadingCell, TheListRow, TheListTable } from "./TheListTable";

export const TheList: React.FC<{
  contexts: ContactContextType[],
  sourceContexts: ContactSourceContextType[],
}> = ({ contexts, sourceContexts }) => {
  const [{ fetching, data, error }] = useListQuery({
    variables: {
      contexts,
      sourceContexts,
    },
  });

  if(error) {
    return (
      <TheListContainer>
        Error.
      </TheListContainer>
    );
  }

  if(fetching || !data || !data.contacts) {
    return (
      <TheListContainer>
        <TheListHeader />
        <TheListTable>
          <FullRow><LoadingCell /></FullRow>
          <FullRow><LoadingCell /></FullRow>
          <FullRow><LoadingCell /></FullRow>
          <FullRow><LoadingCell /></FullRow>
          <FullRow><LoadingCell /></FullRow>
        </TheListTable>
      </TheListContainer>
    )
  }

  const rows = data.contacts.map(contact => {
    const list = contact.publishedList;
    return {
      id: contact.id,
      name: contact.name ?? null,
      relevance: !contact.relevance ? null : contact.relevance.map(rel => ({
        id: rel.id,
        description: rel.description,
      })),
      views: (!list || [ContactPublishedListType.SUSPECTED].includes(list))
        ? "Suspected Transphobe"
        : [ContactPublishedListType.TRANSPHOBE, ContactPublishedListType.PROLIFIC].includes(list)
          ? "Transphobe"
          : "",
      behavior: contact.behavior?.map(b => ({
        label: b.summary,
        section: b.profileSection,
      })) ?? null,
    };
  });

  return (
    <TheListContainer>
      <TheListHeader />
      <TheListTable>
        {
          rows.map(row => (
            <TheListRow
              key={row.id}
              id={row.id}
              name={row.name}
              relevance={row.relevance
                ? row.relevance.map(r => r.description).filter((x): x is string => typeof x === "string")
                : null
              }
              views={row.views}
              behavior={row.behavior}
            />
          ))
        }
      </TheListTable>
    </TheListContainer>
  );
};
