import {
  Box,
  Container,
  Flex,
  Heading,
  Text,
} from "@chakra-ui/react";
import React from "react";

export const WhyInfoBox = () => (
  <Container maxW="container.md" mt={8}>
    <Box layerStyle="info-why" p={4}>
      <Flex>
        <Heading as="h2" size="lg">Why?</Heading>
        <Box flex="1" ml={2}>
          <Heading align="center" size="md">Transphobia is rampant in sports.</Heading>
          <Text align="left" fontSize="md">
            This isn't news, nor a recent development.<br />
            However when looking at transphobic content in cycling circles,
            it's sometimes shocking how many race staff, widely respected athletes,
            and friends-of-friends are supporting the transphobic sentiments and
            vocal transphobes.
          </Text>
          <Text align="left" fontSize="md">
            <b>Hate speech isn't welcome in cycling.</b>
          </Text>
        </Box>
      </Flex>
    </Box>
  </Container>
);
