import React from "react";
import {
  Box,
  BoxProps,
  Button,
  Center,
  chakra,
  Heading,
  Image,
  ImageProps,
} from "@chakra-ui/react";
import { WarnedPlaceholder } from "./util/WarnedPlaceholder";
import { WarnedContent } from "./util/WarnedContent";
import { Warning } from "./util/Warning";
import { useWarnContext, Warned } from "./util/Warned";

export type WarnedImagePlaceholderConfig = {
  width: number;
  height: number;
} | "preview";

const WarnedImagePlaceholder: React.FC<{
  width?: BoxProps["width"];
  placeholder?: WarnedImagePlaceholderConfig;
  backupUrl?: ImageProps["src"] | undefined | null;
}> = ({ width, placeholder, backupUrl }) => {
  const { warnings, openUi } = useWarnContext();
  const w = (!placeholder || placeholder === "preview") ? 688 : placeholder.width;
  const h = (!placeholder || placeholder === "preview") ? 500 : placeholder.height;
  return (
    <WarnedPlaceholder>
      <Box pos="relative" width={width}>
        <Image
          src={backupUrl ?? `https://placekitten.com/${w}/${h}`}
          filter={"blur(10px)"}
          overflow="hidden"
          width={width}
          zIndex="1"
        />
        <Box pos="absolute" width={width} zIndex="1000" top="0" left="0">
          <Heading size="md" align="center" mt={1}>{ warnings.all.length }</Heading>
          <Heading size="sm" align="center">Content Warnings</Heading>
          <Center>
            <Button onClick={() => openUi()}>View</Button>
          </Center>
        </Box>
        <chakra.div width={width} marginRight="0px" />
      </Box>
    </WarnedPlaceholder>
  );
};

/*
const WarnedImageBlocked: React.FC<{
  placeholder?: WarnedImagePlaceholder;
}> = ({ placeholder }) => {
  const w = (!placeholder || placeholder === "preview") ? 688 : placeholder.width;
  const h = (!placeholder || placeholder === "preview") ? 500 : placeholder.height;
  return (
    <WarnedPlaceholder>
      <Image src={`https://placekitten.com/${w}/${h}`} filter={"blur(5px)"} />
    </WarnedPlaceholder>
  );
}
*/

export const WarnedImage: React.FC<{
  warnings: Warning[];
  borderRadius?: ImageProps["borderRadius"];
  width?: BoxProps["width"];
  src?: ImageProps["src"] | undefined | null;
  /**
   * A URL to an image to use as the 'content warning free' version
   */
  backupUrl?: ImageProps["src"] | undefined | null;
  placeholder?: WarnedImagePlaceholderConfig;
}> = ({ warnings, borderRadius, width, src, placeholder, backupUrl }) => {
  return (
    <Warned warnings={warnings}>
      <WarnedPlaceholder>
        <WarnedImagePlaceholder width={width} placeholder={placeholder} backupUrl={backupUrl} />
      </WarnedPlaceholder>
      <WarnedContent>
        <Image
          borderRadius={borderRadius}
          width={width}
          src={src ?? undefined}
        />
      </WarnedContent>
    </Warned>
  );
};
