import React from "react";
import {
  Box,
  Container,
  Flex,
  Heading,
  Link,
  Skeleton,
  Spacer,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
  Text,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import {
  ContactContextType,
  ContactSourceContextType,
  useListLengthQuery,
} from "../generated/graphql";

export const ListLink: React.FC<{
  name?: string,
  community?: string,
  caption?: string,
  to: string,
  contexts: ContactContextType[],
  sourceContexts: ContactSourceContextType[],
}> = ({ name, community, caption, to, contexts, sourceContexts, children }) => {
  const [{ fetching, data, error }] = useListLengthQuery({
    variables: {
      contexts,
      sourceContexts,
    },
  });
  const loading = fetching || (!error && !data?.contactsCount);
  const errState = !!error;
  const title = community ? `${community} Community` : name;
  return (
    <Container maxW="container.md" my={3}>
      <Box layerStyle="info-promo" p={4}>
        <Flex>
          <Box px={2}>
            <Heading as="h2" size="md">
              <Link as={RouterLink} to={to}>
                { name ?? community } List
              </Link>
            </Heading>
            { children }
            {
              caption && (
                <Text textTransform="lowercase" fontWeight="bold" fontSize="sm">
                  { caption }
                </Text>
              )
            }
          </Box>
          <Spacer />
          <Box px={2}>
            <Stat>
              <StatLabel>{ title }</StatLabel>
              <StatNumber as={RouterLink} to={to}>
                {
                  loading
                    ? <Skeleton width="2em" />
                    : errState
                      ? "!"
                      : data?.contactsCount
                }
              </StatNumber>
              <StatHelpText>
                Identified Transphobes
              </StatHelpText>
            </Stat>
          </Box>
        </Flex>
      </Box>
    </Container>
  );
}

export const HomepagePromoListLink = () => (
  <ListLink
    name="All Transphobes"
    to="/"
    contexts={[
      ContactContextType.ALL_SPORTS,
      ContactContextType.CYCLING,
      ContactContextType.SWIMMING,
      ContactContextType.ANY_SPORT,
    ]}
    sourceContexts={[
      ContactSourceContextType.CYCLING,
    ]}
  />
);
