import React from "react";
import {
  Box,
  Text,
  Heading,
  VStack,
  Flex,
  Center,
  Container,
} from "@chakra-ui/react";
import { ColorModeSwitcher } from "../ColorModeSwitcher";
import { DataBasis } from "./DataBasis";
import { ListLink } from "./ListLink";
import { TheList } from "../components/list/TheList";
import { Link } from "react-router-dom";
import { ContactContextType, ContactSourceContextType } from "../generated/graphql";

export const Homepage = () => (
  <React.Fragment>
    <Flex p={3}>
      <Box flex="1">
        <Center>
          <VStack textAlign="center">
            <Heading as="h1" size="3xl">TTT</Heading>
            <Text fontSize="md">Transphobe/TERF Tracker</Text>
            <Text fontSize="2xl">Tracking transphobes in sports</Text>
          </VStack>
        </Center>
      </Box>
      <ColorModeSwitcher justifySelf="flex-end" />
    </Flex>
    <DataBasis />
    <ListLink
      community="Cycling"
      caption="where we started"
      to="/cycling/"
      contexts={[
        ContactContextType.ALL_SPORTS,
        ContactContextType.CYCLING,
        ContactContextType.ANY_SPORT,
      ]}
      sourceContexts={[
        ContactSourceContextType.CYCLING,
      ]}
    >
    </ListLink>
    { /*
    <Container maxW="container.md" mt={4}>
      <SimpleGrid columns={[ 1, 2 ]} spacing="5px">
        <Box layerStyle="info-research" p={3}>
          <Heading as="h2" size="md">Researchers</Heading>
          <Text align="left" fontSize="md">
            Our data set could be made available for researchers...
          </Text>
        </Box>
      </SimpleGrid>
    </Container>
    */ }
    <TheList
      contexts={[
        ContactContextType.ALL_SPORTS,
        ContactContextType.CYCLING,
        ContactContextType.SWIMMING,
        ContactContextType.ANY_SPORT,
      ]}
      sourceContexts={[
        ContactSourceContextType.GENERAL_SPORTS,
        ContactSourceContextType.CYCLING,
        ContactSourceContextType.SWIMMING,
      ]}
    />
    <Container maxW="container.lg" mt="4" mb="2">
      <Link to="/login/">Login</Link>
    </Container>
  </React.Fragment>
);
