import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type AttachedWarning = {
  __typename?: 'AttachedWarning';
  censorDescription?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  strength?: Maybe<AttachedWarningStrengthType>;
  warning?: Maybe<ContentWarning>;
};

export type AttachedWarningCreateInput = {
  censorDescription?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  strength?: InputMaybe<AttachedWarningStrengthType>;
  warning?: InputMaybe<ContentWarningRelateToOneForCreateInput>;
};

export type AttachedWarningManyRelationFilter = {
  every?: InputMaybe<AttachedWarningWhereInput>;
  none?: InputMaybe<AttachedWarningWhereInput>;
  some?: InputMaybe<AttachedWarningWhereInput>;
};

export type AttachedWarningOrderByInput = {
  censorDescription?: InputMaybe<OrderDirection>;
  description?: InputMaybe<OrderDirection>;
  id?: InputMaybe<OrderDirection>;
  strength?: InputMaybe<OrderDirection>;
};

export type AttachedWarningRelateToManyForCreateInput = {
  connect?: InputMaybe<Array<AttachedWarningWhereUniqueInput>>;
  create?: InputMaybe<Array<AttachedWarningCreateInput>>;
};

export type AttachedWarningRelateToManyForUpdateInput = {
  connect?: InputMaybe<Array<AttachedWarningWhereUniqueInput>>;
  create?: InputMaybe<Array<AttachedWarningCreateInput>>;
  disconnect?: InputMaybe<Array<AttachedWarningWhereUniqueInput>>;
  set?: InputMaybe<Array<AttachedWarningWhereUniqueInput>>;
};

export enum AttachedWarningStrengthType {
  EMPTY = 'empty',
  MODERATE = 'moderate',
  SOLID = 'solid',
  STRONG = 'strong',
  WEAK = 'weak'
}

export type AttachedWarningStrengthTypeNullableFilter = {
  equals?: InputMaybe<AttachedWarningStrengthType>;
  in?: InputMaybe<Array<AttachedWarningStrengthType>>;
  not?: InputMaybe<AttachedWarningStrengthTypeNullableFilter>;
  notIn?: InputMaybe<Array<AttachedWarningStrengthType>>;
};

export type AttachedWarningUpdateArgs = {
  data: AttachedWarningUpdateInput;
  where: AttachedWarningWhereUniqueInput;
};

export type AttachedWarningUpdateInput = {
  censorDescription?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  strength?: InputMaybe<AttachedWarningStrengthType>;
  warning?: InputMaybe<ContentWarningRelateToOneForUpdateInput>;
};

export type AttachedWarningWhereInput = {
  AND?: InputMaybe<Array<AttachedWarningWhereInput>>;
  NOT?: InputMaybe<Array<AttachedWarningWhereInput>>;
  OR?: InputMaybe<Array<AttachedWarningWhereInput>>;
  censorDescription?: InputMaybe<BooleanFilter>;
  description?: InputMaybe<StringFilter>;
  id?: InputMaybe<IdFilter>;
  strength?: InputMaybe<AttachedWarningStrengthTypeNullableFilter>;
  warning?: InputMaybe<ContentWarningWhereInput>;
};

export type AttachedWarningWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type AuthenticatedItem = User;

export type BooleanFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<BooleanFilter>;
};

export type CloudImageFieldOutput = ImageFieldOutput & {
  __typename?: 'CloudImageFieldOutput';
  extension: ImageExtension;
  filesize: Scalars['Int'];
  height: Scalars['Int'];
  id: Scalars['ID'];
  ref: Scalars['String'];
  url: Scalars['String'];
  width: Scalars['Int'];
};

export type Contact = {
  __typename?: 'Contact';
  behavior?: Maybe<Array<ContactBehavior>>;
  behaviorCount?: Maybe<Scalars['Int']>;
  contactType?: Maybe<ContactContactTypeType>;
  context?: Maybe<ContactContextType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  limitedVisibilityReason?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nameVisibility?: Maybe<ContactNameVisibilityType>;
  order?: Maybe<Scalars['Int']>;
  posts?: Maybe<Array<Post>>;
  postsCount?: Maybe<Scalars['Int']>;
  profiles?: Maybe<Array<Page>>;
  profilesCount?: Maybe<Scalars['Int']>;
  publishedList?: Maybe<ContactPublishedListType>;
  reactions?: Maybe<Array<Reaction>>;
  reactionsCount?: Maybe<Scalars['Int']>;
  ref?: Maybe<Scalars['String']>;
  relevance?: Maybe<Array<ContactRelevance>>;
  relevanceCount?: Maybe<Scalars['Int']>;
  sourceContext?: Maybe<ContactSourceContextType>;
  visibility?: Maybe<ContactVisibilityType>;
};


export type ContactBehaviorArgs = {
  orderBy?: Array<ContactBehaviorOrderByInput>;
  skip?: Scalars['Int'];
  take?: InputMaybe<Scalars['Int']>;
  where?: ContactBehaviorWhereInput;
};


export type ContactBehaviorCountArgs = {
  where?: ContactBehaviorWhereInput;
};


export type ContactPostsArgs = {
  orderBy?: Array<PostOrderByInput>;
  skip?: Scalars['Int'];
  take?: InputMaybe<Scalars['Int']>;
  where?: PostWhereInput;
};


export type ContactPostsCountArgs = {
  where?: PostWhereInput;
};


export type ContactProfilesArgs = {
  orderBy?: Array<PageOrderByInput>;
  skip?: Scalars['Int'];
  take?: InputMaybe<Scalars['Int']>;
  where?: PageWhereInput;
};


export type ContactProfilesCountArgs = {
  where?: PageWhereInput;
};


export type ContactReactionsArgs = {
  orderBy?: Array<ReactionOrderByInput>;
  skip?: Scalars['Int'];
  take?: InputMaybe<Scalars['Int']>;
  where?: ReactionWhereInput;
};


export type ContactReactionsCountArgs = {
  where?: ReactionWhereInput;
};


export type ContactRelevanceArgs = {
  orderBy?: Array<ContactRelevanceOrderByInput>;
  skip?: Scalars['Int'];
  take?: InputMaybe<Scalars['Int']>;
  where?: ContactRelevanceWhereInput;
};


export type ContactRelevanceCountArgs = {
  where?: ContactRelevanceWhereInput;
};

export type ContactBehavior = {
  __typename?: 'ContactBehavior';
  contact?: Maybe<Contact>;
  content?: Maybe<Array<Post>>;
  contentCount?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  listedBehavior?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  profileSection?: Maybe<Scalars['String']>;
  reactions?: Maybe<Array<Reaction>>;
  reactionsCount?: Maybe<Scalars['Int']>;
  summary?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  visibility?: Maybe<ContactBehaviorVisibilityType>;
};


export type ContactBehaviorContentArgs = {
  orderBy?: Array<PostOrderByInput>;
  skip?: Scalars['Int'];
  take?: InputMaybe<Scalars['Int']>;
  where?: PostWhereInput;
};


export type ContactBehaviorContentCountArgs = {
  where?: PostWhereInput;
};


export type ContactBehaviorReactionsArgs = {
  orderBy?: Array<ReactionOrderByInput>;
  skip?: Scalars['Int'];
  take?: InputMaybe<Scalars['Int']>;
  where?: ReactionWhereInput;
};


export type ContactBehaviorReactionsCountArgs = {
  where?: ReactionWhereInput;
};

export type ContactBehaviorCreateInput = {
  contact?: InputMaybe<ContactRelateToOneForCreateInput>;
  content?: InputMaybe<PostRelateToManyForCreateInput>;
  listedBehavior?: InputMaybe<Scalars['Boolean']>;
  order?: InputMaybe<Scalars['Int']>;
  profileSection?: InputMaybe<Scalars['String']>;
  reactions?: InputMaybe<ReactionRelateToManyForCreateInput>;
  type?: InputMaybe<Scalars['String']>;
  visibility?: InputMaybe<ContactBehaviorVisibilityType>;
};

export type ContactBehaviorManyRelationFilter = {
  every?: InputMaybe<ContactBehaviorWhereInput>;
  none?: InputMaybe<ContactBehaviorWhereInput>;
  some?: InputMaybe<ContactBehaviorWhereInput>;
};

export type ContactBehaviorOrderByInput = {
  id?: InputMaybe<OrderDirection>;
  listedBehavior?: InputMaybe<OrderDirection>;
  order?: InputMaybe<OrderDirection>;
  profileSection?: InputMaybe<OrderDirection>;
  type?: InputMaybe<OrderDirection>;
  visibility?: InputMaybe<OrderDirection>;
};

export type ContactBehaviorRelateToManyForCreateInput = {
  connect?: InputMaybe<Array<ContactBehaviorWhereUniqueInput>>;
  create?: InputMaybe<Array<ContactBehaviorCreateInput>>;
};

export type ContactBehaviorRelateToManyForUpdateInput = {
  connect?: InputMaybe<Array<ContactBehaviorWhereUniqueInput>>;
  create?: InputMaybe<Array<ContactBehaviorCreateInput>>;
  disconnect?: InputMaybe<Array<ContactBehaviorWhereUniqueInput>>;
  set?: InputMaybe<Array<ContactBehaviorWhereUniqueInput>>;
};

export type ContactBehaviorUpdateArgs = {
  data: ContactBehaviorUpdateInput;
  where: ContactBehaviorWhereUniqueInput;
};

export type ContactBehaviorUpdateInput = {
  contact?: InputMaybe<ContactRelateToOneForUpdateInput>;
  content?: InputMaybe<PostRelateToManyForUpdateInput>;
  listedBehavior?: InputMaybe<Scalars['Boolean']>;
  order?: InputMaybe<Scalars['Int']>;
  profileSection?: InputMaybe<Scalars['String']>;
  reactions?: InputMaybe<ReactionRelateToManyForUpdateInput>;
  type?: InputMaybe<Scalars['String']>;
  visibility?: InputMaybe<ContactBehaviorVisibilityType>;
};

export enum ContactBehaviorVisibilityType {
  ADMIN_ONLY = 'admin_only',
  CONTRIBUTORS = 'contributors',
  KNOWN_CONTRIBUTORS = 'known_contributors',
  MODERATORS = 'moderators',
  PUBLIC = 'public',
  TRUSTED_CONTRIBUTORS = 'trusted_contributors'
}

export type ContactBehaviorVisibilityTypeNullableFilter = {
  equals?: InputMaybe<ContactBehaviorVisibilityType>;
  in?: InputMaybe<Array<ContactBehaviorVisibilityType>>;
  not?: InputMaybe<ContactBehaviorVisibilityTypeNullableFilter>;
  notIn?: InputMaybe<Array<ContactBehaviorVisibilityType>>;
};

export type ContactBehaviorWhereInput = {
  AND?: InputMaybe<Array<ContactBehaviorWhereInput>>;
  NOT?: InputMaybe<Array<ContactBehaviorWhereInput>>;
  OR?: InputMaybe<Array<ContactBehaviorWhereInput>>;
  contact?: InputMaybe<ContactWhereInput>;
  content?: InputMaybe<PostManyRelationFilter>;
  id?: InputMaybe<IdFilter>;
  listedBehavior?: InputMaybe<BooleanFilter>;
  order?: InputMaybe<IntNullableFilter>;
  profileSection?: InputMaybe<StringFilter>;
  reactions?: InputMaybe<ReactionManyRelationFilter>;
  type?: InputMaybe<StringNullableFilter>;
  visibility?: InputMaybe<ContactBehaviorVisibilityTypeNullableFilter>;
};

export type ContactBehaviorWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export enum ContactContactTypeType {
  GROUP = 'group',
  INDIVIDUAL = 'individual',
  ORG = 'org'
}

export type ContactContactTypeTypeNullableFilter = {
  equals?: InputMaybe<ContactContactTypeType>;
  in?: InputMaybe<Array<ContactContactTypeType>>;
  not?: InputMaybe<ContactContactTypeTypeNullableFilter>;
  notIn?: InputMaybe<Array<ContactContactTypeType>>;
};

export enum ContactContextType {
  ALL_SPORTS = 'all_sports',
  ANY_SPORT = 'any_sport',
  CYCLING = 'cycling',
  SWIMMING = 'swimming'
}

export type ContactContextTypeNullableFilter = {
  equals?: InputMaybe<ContactContextType>;
  in?: InputMaybe<Array<ContactContextType>>;
  not?: InputMaybe<ContactContextTypeNullableFilter>;
  notIn?: InputMaybe<Array<ContactContextType>>;
};

export type ContactCreateInput = {
  behavior?: InputMaybe<ContactBehaviorRelateToManyForCreateInput>;
  contactType?: InputMaybe<ContactContactTypeType>;
  context?: InputMaybe<ContactContextType>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  limitedVisibilityReason?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  nameVisibility?: InputMaybe<ContactNameVisibilityType>;
  order?: InputMaybe<Scalars['Int']>;
  posts?: InputMaybe<PostRelateToManyForCreateInput>;
  profiles?: InputMaybe<PageRelateToManyForCreateInput>;
  publishedList?: InputMaybe<ContactPublishedListType>;
  reactions?: InputMaybe<ReactionRelateToManyForCreateInput>;
  ref?: InputMaybe<Scalars['String']>;
  relevance?: InputMaybe<ContactRelevanceRelateToManyForCreateInput>;
  sourceContext?: InputMaybe<ContactSourceContextType>;
  visibility?: InputMaybe<ContactVisibilityType>;
};

export type ContactManyRelationFilter = {
  every?: InputMaybe<ContactWhereInput>;
  none?: InputMaybe<ContactWhereInput>;
  some?: InputMaybe<ContactWhereInput>;
};

export enum ContactNameVisibilityType {
  ADMIN_ONLY = 'admin_only',
  CONTRIBUTORS = 'contributors',
  KNOWN_CONTRIBUTORS = 'known_contributors',
  MODERATORS = 'moderators',
  TRUSTED_CONTRIBUTORS = 'trusted_contributors'
}

export type ContactNameVisibilityTypeNullableFilter = {
  equals?: InputMaybe<ContactNameVisibilityType>;
  in?: InputMaybe<Array<ContactNameVisibilityType>>;
  not?: InputMaybe<ContactNameVisibilityTypeNullableFilter>;
  notIn?: InputMaybe<Array<ContactNameVisibilityType>>;
};

export type ContactOrderByInput = {
  contactType?: InputMaybe<OrderDirection>;
  context?: InputMaybe<OrderDirection>;
  createdAt?: InputMaybe<OrderDirection>;
  id?: InputMaybe<OrderDirection>;
  limitedVisibilityReason?: InputMaybe<OrderDirection>;
  name?: InputMaybe<OrderDirection>;
  nameVisibility?: InputMaybe<OrderDirection>;
  order?: InputMaybe<OrderDirection>;
  publishedList?: InputMaybe<OrderDirection>;
  ref?: InputMaybe<OrderDirection>;
  sourceContext?: InputMaybe<OrderDirection>;
  visibility?: InputMaybe<OrderDirection>;
};

export enum ContactPublishedListType {
  ACTIVIST = 'activist',
  ALLY = 'ally',
  LIKELY_ALLY = 'likely_ally',
  LOGGED = 'logged',
  PROLIFIC = 'prolific',
  PUBLIC_ACTIVIST = 'public_activist',
  SUSPECTED = 'suspected',
  TRANSPHOBE = 'transphobe'
}

export type ContactPublishedListTypeNullableFilter = {
  equals?: InputMaybe<ContactPublishedListType>;
  in?: InputMaybe<Array<ContactPublishedListType>>;
  not?: InputMaybe<ContactPublishedListTypeNullableFilter>;
  notIn?: InputMaybe<Array<ContactPublishedListType>>;
};

export type ContactRelateToManyForCreateInput = {
  connect?: InputMaybe<Array<ContactWhereUniqueInput>>;
  create?: InputMaybe<Array<ContactCreateInput>>;
};

export type ContactRelateToManyForUpdateInput = {
  connect?: InputMaybe<Array<ContactWhereUniqueInput>>;
  create?: InputMaybe<Array<ContactCreateInput>>;
  disconnect?: InputMaybe<Array<ContactWhereUniqueInput>>;
  set?: InputMaybe<Array<ContactWhereUniqueInput>>;
};

export type ContactRelateToOneForCreateInput = {
  connect?: InputMaybe<ContactWhereUniqueInput>;
  create?: InputMaybe<ContactCreateInput>;
};

export type ContactRelateToOneForUpdateInput = {
  connect?: InputMaybe<ContactWhereUniqueInput>;
  create?: InputMaybe<ContactCreateInput>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
};

export type ContactRelevance = {
  __typename?: 'ContactRelevance';
  contact?: Maybe<Contact>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  visibility?: Maybe<ContactRelevanceVisibilityType>;
};

export type ContactRelevanceCreateInput = {
  contact?: InputMaybe<ContactRelateToOneForCreateInput>;
  description?: InputMaybe<Scalars['String']>;
  visibility?: InputMaybe<ContactRelevanceVisibilityType>;
};

export type ContactRelevanceManyRelationFilter = {
  every?: InputMaybe<ContactRelevanceWhereInput>;
  none?: InputMaybe<ContactRelevanceWhereInput>;
  some?: InputMaybe<ContactRelevanceWhereInput>;
};

export type ContactRelevanceOrderByInput = {
  description?: InputMaybe<OrderDirection>;
  id?: InputMaybe<OrderDirection>;
  visibility?: InputMaybe<OrderDirection>;
};

export type ContactRelevanceRelateToManyForCreateInput = {
  connect?: InputMaybe<Array<ContactRelevanceWhereUniqueInput>>;
  create?: InputMaybe<Array<ContactRelevanceCreateInput>>;
};

export type ContactRelevanceRelateToManyForUpdateInput = {
  connect?: InputMaybe<Array<ContactRelevanceWhereUniqueInput>>;
  create?: InputMaybe<Array<ContactRelevanceCreateInput>>;
  disconnect?: InputMaybe<Array<ContactRelevanceWhereUniqueInput>>;
  set?: InputMaybe<Array<ContactRelevanceWhereUniqueInput>>;
};

export type ContactRelevanceUpdateArgs = {
  data: ContactRelevanceUpdateInput;
  where: ContactRelevanceWhereUniqueInput;
};

export type ContactRelevanceUpdateInput = {
  contact?: InputMaybe<ContactRelateToOneForUpdateInput>;
  description?: InputMaybe<Scalars['String']>;
  visibility?: InputMaybe<ContactRelevanceVisibilityType>;
};

export enum ContactRelevanceVisibilityType {
  ADMIN_ONLY = 'admin_only',
  CONTRIBUTORS = 'contributors',
  KNOWN_CONTRIBUTORS = 'known_contributors',
  MODERATORS = 'moderators',
  TRUSTED_CONTRIBUTORS = 'trusted_contributors'
}

export type ContactRelevanceVisibilityTypeNullableFilter = {
  equals?: InputMaybe<ContactRelevanceVisibilityType>;
  in?: InputMaybe<Array<ContactRelevanceVisibilityType>>;
  not?: InputMaybe<ContactRelevanceVisibilityTypeNullableFilter>;
  notIn?: InputMaybe<Array<ContactRelevanceVisibilityType>>;
};

export type ContactRelevanceWhereInput = {
  AND?: InputMaybe<Array<ContactRelevanceWhereInput>>;
  NOT?: InputMaybe<Array<ContactRelevanceWhereInput>>;
  OR?: InputMaybe<Array<ContactRelevanceWhereInput>>;
  contact?: InputMaybe<ContactWhereInput>;
  description?: InputMaybe<StringFilter>;
  id?: InputMaybe<IdFilter>;
  visibility?: InputMaybe<ContactRelevanceVisibilityTypeNullableFilter>;
};

export type ContactRelevanceWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export enum ContactSourceContextType {
  CYCLING = 'cycling',
  GENERAL_SPORTS = 'general_sports',
  SWIMMING = 'swimming'
}

export type ContactSourceContextTypeNullableFilter = {
  equals?: InputMaybe<ContactSourceContextType>;
  in?: InputMaybe<Array<ContactSourceContextType>>;
  not?: InputMaybe<ContactSourceContextTypeNullableFilter>;
  notIn?: InputMaybe<Array<ContactSourceContextType>>;
};

export type ContactUpdateArgs = {
  data: ContactUpdateInput;
  where: ContactWhereUniqueInput;
};

export type ContactUpdateInput = {
  behavior?: InputMaybe<ContactBehaviorRelateToManyForUpdateInput>;
  contactType?: InputMaybe<ContactContactTypeType>;
  context?: InputMaybe<ContactContextType>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  limitedVisibilityReason?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  nameVisibility?: InputMaybe<ContactNameVisibilityType>;
  order?: InputMaybe<Scalars['Int']>;
  posts?: InputMaybe<PostRelateToManyForUpdateInput>;
  profiles?: InputMaybe<PageRelateToManyForUpdateInput>;
  publishedList?: InputMaybe<ContactPublishedListType>;
  reactions?: InputMaybe<ReactionRelateToManyForUpdateInput>;
  ref?: InputMaybe<Scalars['String']>;
  relevance?: InputMaybe<ContactRelevanceRelateToManyForUpdateInput>;
  sourceContext?: InputMaybe<ContactSourceContextType>;
  visibility?: InputMaybe<ContactVisibilityType>;
};

export enum ContactVisibilityType {
  ADMIN_ONLY = 'admin_only',
  CONTRIBUTORS = 'contributors',
  KNOWN_CONTRIBUTORS = 'known_contributors',
  MODERATORS = 'moderators',
  TRUSTED_CONTRIBUTORS = 'trusted_contributors'
}

export type ContactVisibilityTypeNullableFilter = {
  equals?: InputMaybe<ContactVisibilityType>;
  in?: InputMaybe<Array<ContactVisibilityType>>;
  not?: InputMaybe<ContactVisibilityTypeNullableFilter>;
  notIn?: InputMaybe<Array<ContactVisibilityType>>;
};

export type ContactWhereInput = {
  AND?: InputMaybe<Array<ContactWhereInput>>;
  NOT?: InputMaybe<Array<ContactWhereInput>>;
  OR?: InputMaybe<Array<ContactWhereInput>>;
  behavior?: InputMaybe<ContactBehaviorManyRelationFilter>;
  contactType?: InputMaybe<ContactContactTypeTypeNullableFilter>;
  context?: InputMaybe<ContactContextTypeNullableFilter>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<IdFilter>;
  limitedVisibilityReason?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringFilter>;
  nameVisibility?: InputMaybe<ContactNameVisibilityTypeNullableFilter>;
  order?: InputMaybe<IntNullableFilter>;
  posts?: InputMaybe<PostManyRelationFilter>;
  profiles?: InputMaybe<PageManyRelationFilter>;
  publishedList?: InputMaybe<ContactPublishedListTypeNullableFilter>;
  reactions?: InputMaybe<ReactionManyRelationFilter>;
  ref?: InputMaybe<StringFilter>;
  relevance?: InputMaybe<ContactRelevanceManyRelationFilter>;
  sourceContext?: InputMaybe<ContactSourceContextTypeNullableFilter>;
  visibility?: InputMaybe<ContactVisibilityTypeNullableFilter>;
};

export type ContactWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
  ref?: InputMaybe<Scalars['String']>;
};

export type ContentWarning = {
  __typename?: 'ContentWarning';
  children?: Maybe<Array<ContentWarning>>;
  childrenCount?: Maybe<Scalars['Int']>;
  fullIdx?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  idx?: Maybe<Scalars['Int']>;
  label?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  parent?: Maybe<ContentWarning>;
};


export type ContentWarningChildrenArgs = {
  orderBy?: Array<ContentWarningOrderByInput>;
  skip?: Scalars['Int'];
  take?: InputMaybe<Scalars['Int']>;
  where?: ContentWarningWhereInput;
};


export type ContentWarningChildrenCountArgs = {
  where?: ContentWarningWhereInput;
};

export type ContentWarningCreateInput = {
  children?: InputMaybe<ContentWarningRelateToManyForCreateInput>;
  fullIdx?: InputMaybe<Scalars['String']>;
  idx?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  parent?: InputMaybe<ContentWarningRelateToOneForCreateInput>;
};

export type ContentWarningManyRelationFilter = {
  every?: InputMaybe<ContentWarningWhereInput>;
  none?: InputMaybe<ContentWarningWhereInput>;
  some?: InputMaybe<ContentWarningWhereInput>;
};

export type ContentWarningOrderByInput = {
  fullIdx?: InputMaybe<OrderDirection>;
  id?: InputMaybe<OrderDirection>;
  idx?: InputMaybe<OrderDirection>;
  name?: InputMaybe<OrderDirection>;
};

export type ContentWarningRelateToManyForCreateInput = {
  connect?: InputMaybe<Array<ContentWarningWhereUniqueInput>>;
  create?: InputMaybe<Array<ContentWarningCreateInput>>;
};

export type ContentWarningRelateToManyForUpdateInput = {
  connect?: InputMaybe<Array<ContentWarningWhereUniqueInput>>;
  create?: InputMaybe<Array<ContentWarningCreateInput>>;
  disconnect?: InputMaybe<Array<ContentWarningWhereUniqueInput>>;
  set?: InputMaybe<Array<ContentWarningWhereUniqueInput>>;
};

export type ContentWarningRelateToOneForCreateInput = {
  connect?: InputMaybe<ContentWarningWhereUniqueInput>;
  create?: InputMaybe<ContentWarningCreateInput>;
};

export type ContentWarningRelateToOneForUpdateInput = {
  connect?: InputMaybe<ContentWarningWhereUniqueInput>;
  create?: InputMaybe<ContentWarningCreateInput>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
};

export type ContentWarningUpdateArgs = {
  data: ContentWarningUpdateInput;
  where: ContentWarningWhereUniqueInput;
};

export type ContentWarningUpdateInput = {
  children?: InputMaybe<ContentWarningRelateToManyForUpdateInput>;
  fullIdx?: InputMaybe<Scalars['String']>;
  idx?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  parent?: InputMaybe<ContentWarningRelateToOneForUpdateInput>;
};

export type ContentWarningWhereInput = {
  AND?: InputMaybe<Array<ContentWarningWhereInput>>;
  NOT?: InputMaybe<Array<ContentWarningWhereInput>>;
  OR?: InputMaybe<Array<ContentWarningWhereInput>>;
  children?: InputMaybe<ContentWarningManyRelationFilter>;
  fullIdx?: InputMaybe<StringFilter>;
  id?: InputMaybe<IdFilter>;
  idx?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  parent?: InputMaybe<ContentWarningWhereInput>;
};

export type ContentWarningWhereUniqueInput = {
  fullIdx?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
};

export type CreateInitialUserInput = {
  email?: InputMaybe<Scalars['String']>;
  isAdmin?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
};

export type DateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<DateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type IdFilter = {
  equals?: InputMaybe<Scalars['ID']>;
  gt?: InputMaybe<Scalars['ID']>;
  gte?: InputMaybe<Scalars['ID']>;
  in?: InputMaybe<Array<Scalars['ID']>>;
  lt?: InputMaybe<Scalars['ID']>;
  lte?: InputMaybe<Scalars['ID']>;
  not?: InputMaybe<IdFilter>;
  notIn?: InputMaybe<Array<Scalars['ID']>>;
};

export enum ImageExtension {
  GIF = 'gif',
  JPG = 'jpg',
  PNG = 'png',
  WEBP = 'webp'
}

export type ImageFieldInput = {
  ref?: InputMaybe<Scalars['String']>;
  upload?: InputMaybe<Scalars['Upload']>;
};

export type ImageFieldOutput = {
  extension: ImageExtension;
  filesize: Scalars['Int'];
  height: Scalars['Int'];
  id: Scalars['ID'];
  ref: Scalars['String'];
  url: Scalars['String'];
  width: Scalars['Int'];
};

export type Impact = {
  __typename?: 'Impact';
  abstract?: Maybe<Impact>;
  children?: Maybe<Array<Impact>>;
  childrenCount?: Maybe<Scalars['Int']>;
  fullIdx?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  idx?: Maybe<Scalars['Int']>;
  label?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  parent?: Maybe<Impact>;
};


export type ImpactChildrenArgs = {
  orderBy?: Array<ImpactOrderByInput>;
  skip?: Scalars['Int'];
  take?: InputMaybe<Scalars['Int']>;
  where?: ImpactWhereInput;
};


export type ImpactChildrenCountArgs = {
  where?: ImpactWhereInput;
};

export type ImpactCreateInput = {
  abstract?: InputMaybe<ImpactRelateToOneForCreateInput>;
  children?: InputMaybe<ImpactRelateToManyForCreateInput>;
  fullIdx?: InputMaybe<Scalars['String']>;
  idx?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  parent?: InputMaybe<ImpactRelateToOneForCreateInput>;
};

export type ImpactManyRelationFilter = {
  every?: InputMaybe<ImpactWhereInput>;
  none?: InputMaybe<ImpactWhereInput>;
  some?: InputMaybe<ImpactWhereInput>;
};

export type ImpactOrderByInput = {
  fullIdx?: InputMaybe<OrderDirection>;
  id?: InputMaybe<OrderDirection>;
  idx?: InputMaybe<OrderDirection>;
  name?: InputMaybe<OrderDirection>;
};

export type ImpactRelateToManyForCreateInput = {
  connect?: InputMaybe<Array<ImpactWhereUniqueInput>>;
  create?: InputMaybe<Array<ImpactCreateInput>>;
};

export type ImpactRelateToManyForUpdateInput = {
  connect?: InputMaybe<Array<ImpactWhereUniqueInput>>;
  create?: InputMaybe<Array<ImpactCreateInput>>;
  disconnect?: InputMaybe<Array<ImpactWhereUniqueInput>>;
  set?: InputMaybe<Array<ImpactWhereUniqueInput>>;
};

export type ImpactRelateToOneForCreateInput = {
  connect?: InputMaybe<ImpactWhereUniqueInput>;
  create?: InputMaybe<ImpactCreateInput>;
};

export type ImpactRelateToOneForUpdateInput = {
  connect?: InputMaybe<ImpactWhereUniqueInput>;
  create?: InputMaybe<ImpactCreateInput>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
};

export type ImpactUpdateArgs = {
  data: ImpactUpdateInput;
  where: ImpactWhereUniqueInput;
};

export type ImpactUpdateInput = {
  abstract?: InputMaybe<ImpactRelateToOneForUpdateInput>;
  children?: InputMaybe<ImpactRelateToManyForUpdateInput>;
  fullIdx?: InputMaybe<Scalars['String']>;
  idx?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  parent?: InputMaybe<ImpactRelateToOneForUpdateInput>;
};

export type ImpactWhereInput = {
  AND?: InputMaybe<Array<ImpactWhereInput>>;
  NOT?: InputMaybe<Array<ImpactWhereInput>>;
  OR?: InputMaybe<Array<ImpactWhereInput>>;
  abstract?: InputMaybe<ImpactWhereInput>;
  children?: InputMaybe<ImpactManyRelationFilter>;
  fullIdx?: InputMaybe<StringFilter>;
  id?: InputMaybe<IdFilter>;
  idx?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  parent?: InputMaybe<ImpactWhereInput>;
};

export type ImpactWhereUniqueInput = {
  fullIdx?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
};

export type IntFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<IntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type IntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<IntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type KeystoneAdminMeta = {
  __typename?: 'KeystoneAdminMeta';
  enableSessionItem: Scalars['Boolean'];
  enableSignout: Scalars['Boolean'];
  list?: Maybe<KeystoneAdminUiListMeta>;
  lists: Array<KeystoneAdminUiListMeta>;
};


export type KeystoneAdminMetaListArgs = {
  key: Scalars['String'];
};

export type KeystoneAdminUiFieldMeta = {
  __typename?: 'KeystoneAdminUIFieldMeta';
  createView: KeystoneAdminUiFieldMetaCreateView;
  customViewsIndex?: Maybe<Scalars['Int']>;
  fieldMeta?: Maybe<Scalars['JSON']>;
  isFilterable: Scalars['Boolean'];
  isOrderable: Scalars['Boolean'];
  itemView?: Maybe<KeystoneAdminUiFieldMetaItemView>;
  label: Scalars['String'];
  listView: KeystoneAdminUiFieldMetaListView;
  path: Scalars['String'];
  search?: Maybe<QueryMode>;
  viewsIndex: Scalars['Int'];
};


export type KeystoneAdminUiFieldMetaItemViewArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type KeystoneAdminUiFieldMetaCreateView = {
  __typename?: 'KeystoneAdminUIFieldMetaCreateView';
  fieldMode: KeystoneAdminUiFieldMetaCreateViewFieldMode;
};

export enum KeystoneAdminUiFieldMetaCreateViewFieldMode {
  EDIT = 'edit',
  HIDDEN = 'hidden'
}

export type KeystoneAdminUiFieldMetaItemView = {
  __typename?: 'KeystoneAdminUIFieldMetaItemView';
  fieldMode?: Maybe<KeystoneAdminUiFieldMetaItemViewFieldMode>;
};

export enum KeystoneAdminUiFieldMetaItemViewFieldMode {
  EDIT = 'edit',
  HIDDEN = 'hidden',
  READ = 'read'
}

export type KeystoneAdminUiFieldMetaListView = {
  __typename?: 'KeystoneAdminUIFieldMetaListView';
  fieldMode: KeystoneAdminUiFieldMetaListViewFieldMode;
};

export enum KeystoneAdminUiFieldMetaListViewFieldMode {
  HIDDEN = 'hidden',
  READ = 'read'
}

export type KeystoneAdminUiListMeta = {
  __typename?: 'KeystoneAdminUIListMeta';
  description?: Maybe<Scalars['String']>;
  fields: Array<KeystoneAdminUiFieldMeta>;
  hideCreate: Scalars['Boolean'];
  hideDelete: Scalars['Boolean'];
  initialColumns: Array<Scalars['String']>;
  initialSort?: Maybe<KeystoneAdminUiSort>;
  isHidden: Scalars['Boolean'];
  itemQueryName: Scalars['String'];
  key: Scalars['String'];
  label: Scalars['String'];
  labelField: Scalars['String'];
  listQueryName: Scalars['String'];
  pageSize: Scalars['Int'];
  path: Scalars['String'];
  plural: Scalars['String'];
  singular: Scalars['String'];
};

export type KeystoneAdminUiSort = {
  __typename?: 'KeystoneAdminUISort';
  direction: KeystoneAdminUiSortDirection;
  field: Scalars['String'];
};

export enum KeystoneAdminUiSortDirection {
  ASC = 'ASC',
  DESC = 'DESC'
}

export type KeystoneMeta = {
  __typename?: 'KeystoneMeta';
  adminMeta: KeystoneAdminMeta;
};

export type LocalImageFieldOutput = ImageFieldOutput & {
  __typename?: 'LocalImageFieldOutput';
  extension: ImageExtension;
  filesize: Scalars['Int'];
  height: Scalars['Int'];
  id: Scalars['ID'];
  ref: Scalars['String'];
  url: Scalars['String'];
  width: Scalars['Int'];
};

export type Mutation = {
  __typename?: 'Mutation';
  authenticateUserWithPassword?: Maybe<UserAuthenticationWithPasswordResult>;
  createAttachedWarning?: Maybe<AttachedWarning>;
  createAttachedWarnings?: Maybe<Array<Maybe<AttachedWarning>>>;
  createContact?: Maybe<Contact>;
  createContactBehavior?: Maybe<ContactBehavior>;
  createContactBehaviors?: Maybe<Array<Maybe<ContactBehavior>>>;
  createContactRelevance?: Maybe<ContactRelevance>;
  createContactRelevances?: Maybe<Array<Maybe<ContactRelevance>>>;
  createContacts?: Maybe<Array<Maybe<Contact>>>;
  createContentWarning?: Maybe<ContentWarning>;
  createContentWarnings?: Maybe<Array<Maybe<ContentWarning>>>;
  createImpact?: Maybe<Impact>;
  createImpacts?: Maybe<Array<Maybe<Impact>>>;
  createInitialUser: UserAuthenticationWithPasswordSuccess;
  createPage?: Maybe<Page>;
  createPages?: Maybe<Array<Maybe<Page>>>;
  createPost?: Maybe<Post>;
  createPostArchive?: Maybe<PostArchive>;
  createPostArchiveItem?: Maybe<PostArchiveItem>;
  createPostArchiveItems?: Maybe<Array<Maybe<PostArchiveItem>>>;
  createPostArchives?: Maybe<Array<Maybe<PostArchive>>>;
  createPostCapture?: Maybe<PostCapture>;
  createPostCaptures?: Maybe<Array<Maybe<PostCapture>>>;
  createPostImpact?: Maybe<PostImpact>;
  createPostImpacts?: Maybe<Array<Maybe<PostImpact>>>;
  createPostPreviewImage?: Maybe<PostPreviewImage>;
  createPostPreviewImages?: Maybe<Array<Maybe<PostPreviewImage>>>;
  createPostSubject?: Maybe<PostSubject>;
  createPostSubjects?: Maybe<Array<Maybe<PostSubject>>>;
  createPostSummaries?: Maybe<Array<Maybe<PostSummary>>>;
  createPostSummary?: Maybe<PostSummary>;
  createPostWarning?: Maybe<PostWarning>;
  createPostWarnings?: Maybe<Array<Maybe<PostWarning>>>;
  createPosts?: Maybe<Array<Maybe<Post>>>;
  createReaction?: Maybe<Reaction>;
  createReactions?: Maybe<Array<Maybe<Reaction>>>;
  createSubject?: Maybe<Subject>;
  createSubjects?: Maybe<Array<Maybe<Subject>>>;
  createUser?: Maybe<User>;
  createUsers?: Maybe<Array<Maybe<User>>>;
  deleteAttachedWarning?: Maybe<AttachedWarning>;
  deleteAttachedWarnings?: Maybe<Array<Maybe<AttachedWarning>>>;
  deleteContact?: Maybe<Contact>;
  deleteContactBehavior?: Maybe<ContactBehavior>;
  deleteContactBehaviors?: Maybe<Array<Maybe<ContactBehavior>>>;
  deleteContactRelevance?: Maybe<ContactRelevance>;
  deleteContactRelevances?: Maybe<Array<Maybe<ContactRelevance>>>;
  deleteContacts?: Maybe<Array<Maybe<Contact>>>;
  deleteContentWarning?: Maybe<ContentWarning>;
  deleteContentWarnings?: Maybe<Array<Maybe<ContentWarning>>>;
  deleteImpact?: Maybe<Impact>;
  deleteImpacts?: Maybe<Array<Maybe<Impact>>>;
  deletePage?: Maybe<Page>;
  deletePages?: Maybe<Array<Maybe<Page>>>;
  deletePost?: Maybe<Post>;
  deletePostArchive?: Maybe<PostArchive>;
  deletePostArchiveItem?: Maybe<PostArchiveItem>;
  deletePostArchiveItems?: Maybe<Array<Maybe<PostArchiveItem>>>;
  deletePostArchives?: Maybe<Array<Maybe<PostArchive>>>;
  deletePostCapture?: Maybe<PostCapture>;
  deletePostCaptures?: Maybe<Array<Maybe<PostCapture>>>;
  deletePostImpact?: Maybe<PostImpact>;
  deletePostImpacts?: Maybe<Array<Maybe<PostImpact>>>;
  deletePostPreviewImage?: Maybe<PostPreviewImage>;
  deletePostPreviewImages?: Maybe<Array<Maybe<PostPreviewImage>>>;
  deletePostSubject?: Maybe<PostSubject>;
  deletePostSubjects?: Maybe<Array<Maybe<PostSubject>>>;
  deletePostSummaries?: Maybe<Array<Maybe<PostSummary>>>;
  deletePostSummary?: Maybe<PostSummary>;
  deletePostWarning?: Maybe<PostWarning>;
  deletePostWarnings?: Maybe<Array<Maybe<PostWarning>>>;
  deletePosts?: Maybe<Array<Maybe<Post>>>;
  deleteReaction?: Maybe<Reaction>;
  deleteReactions?: Maybe<Array<Maybe<Reaction>>>;
  deleteSubject?: Maybe<Subject>;
  deleteSubjects?: Maybe<Array<Maybe<Subject>>>;
  deleteUser?: Maybe<User>;
  deleteUsers?: Maybe<Array<Maybe<User>>>;
  endSession: Scalars['Boolean'];
  updateAttachedWarning?: Maybe<AttachedWarning>;
  updateAttachedWarnings?: Maybe<Array<Maybe<AttachedWarning>>>;
  updateContact?: Maybe<Contact>;
  updateContactBehavior?: Maybe<ContactBehavior>;
  updateContactBehaviors?: Maybe<Array<Maybe<ContactBehavior>>>;
  updateContactRelevance?: Maybe<ContactRelevance>;
  updateContactRelevances?: Maybe<Array<Maybe<ContactRelevance>>>;
  updateContacts?: Maybe<Array<Maybe<Contact>>>;
  updateContentWarning?: Maybe<ContentWarning>;
  updateContentWarnings?: Maybe<Array<Maybe<ContentWarning>>>;
  updateImpact?: Maybe<Impact>;
  updateImpacts?: Maybe<Array<Maybe<Impact>>>;
  updatePage?: Maybe<Page>;
  updatePages?: Maybe<Array<Maybe<Page>>>;
  updatePost?: Maybe<Post>;
  updatePostArchive?: Maybe<PostArchive>;
  updatePostArchiveItem?: Maybe<PostArchiveItem>;
  updatePostArchiveItems?: Maybe<Array<Maybe<PostArchiveItem>>>;
  updatePostArchives?: Maybe<Array<Maybe<PostArchive>>>;
  updatePostCapture?: Maybe<PostCapture>;
  updatePostCaptures?: Maybe<Array<Maybe<PostCapture>>>;
  updatePostImpact?: Maybe<PostImpact>;
  updatePostImpacts?: Maybe<Array<Maybe<PostImpact>>>;
  updatePostPreviewImage?: Maybe<PostPreviewImage>;
  updatePostPreviewImages?: Maybe<Array<Maybe<PostPreviewImage>>>;
  updatePostSubject?: Maybe<PostSubject>;
  updatePostSubjects?: Maybe<Array<Maybe<PostSubject>>>;
  updatePostSummaries?: Maybe<Array<Maybe<PostSummary>>>;
  updatePostSummary?: Maybe<PostSummary>;
  updatePostWarning?: Maybe<PostWarning>;
  updatePostWarnings?: Maybe<Array<Maybe<PostWarning>>>;
  updatePosts?: Maybe<Array<Maybe<Post>>>;
  updateReaction?: Maybe<Reaction>;
  updateReactions?: Maybe<Array<Maybe<Reaction>>>;
  updateSubject?: Maybe<Subject>;
  updateSubjects?: Maybe<Array<Maybe<Subject>>>;
  updateUser?: Maybe<User>;
  updateUsers?: Maybe<Array<Maybe<User>>>;
};


export type MutationAuthenticateUserWithPasswordArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationCreateAttachedWarningArgs = {
  data: AttachedWarningCreateInput;
};


export type MutationCreateAttachedWarningsArgs = {
  data: Array<AttachedWarningCreateInput>;
};


export type MutationCreateContactArgs = {
  data: ContactCreateInput;
};


export type MutationCreateContactBehaviorArgs = {
  data: ContactBehaviorCreateInput;
};


export type MutationCreateContactBehaviorsArgs = {
  data: Array<ContactBehaviorCreateInput>;
};


export type MutationCreateContactRelevanceArgs = {
  data: ContactRelevanceCreateInput;
};


export type MutationCreateContactRelevancesArgs = {
  data: Array<ContactRelevanceCreateInput>;
};


export type MutationCreateContactsArgs = {
  data: Array<ContactCreateInput>;
};


export type MutationCreateContentWarningArgs = {
  data: ContentWarningCreateInput;
};


export type MutationCreateContentWarningsArgs = {
  data: Array<ContentWarningCreateInput>;
};


export type MutationCreateImpactArgs = {
  data: ImpactCreateInput;
};


export type MutationCreateImpactsArgs = {
  data: Array<ImpactCreateInput>;
};


export type MutationCreateInitialUserArgs = {
  data: CreateInitialUserInput;
};


export type MutationCreatePageArgs = {
  data: PageCreateInput;
};


export type MutationCreatePagesArgs = {
  data: Array<PageCreateInput>;
};


export type MutationCreatePostArgs = {
  data: PostCreateInput;
};


export type MutationCreatePostArchiveArgs = {
  data: PostArchiveCreateInput;
};


export type MutationCreatePostArchiveItemArgs = {
  data: PostArchiveItemCreateInput;
};


export type MutationCreatePostArchiveItemsArgs = {
  data: Array<PostArchiveItemCreateInput>;
};


export type MutationCreatePostArchivesArgs = {
  data: Array<PostArchiveCreateInput>;
};


export type MutationCreatePostCaptureArgs = {
  data: PostCaptureCreateInput;
};


export type MutationCreatePostCapturesArgs = {
  data: Array<PostCaptureCreateInput>;
};


export type MutationCreatePostImpactArgs = {
  data: PostImpactCreateInput;
};


export type MutationCreatePostImpactsArgs = {
  data: Array<PostImpactCreateInput>;
};


export type MutationCreatePostPreviewImageArgs = {
  data: PostPreviewImageCreateInput;
};


export type MutationCreatePostPreviewImagesArgs = {
  data: Array<PostPreviewImageCreateInput>;
};


export type MutationCreatePostSubjectArgs = {
  data: PostSubjectCreateInput;
};


export type MutationCreatePostSubjectsArgs = {
  data: Array<PostSubjectCreateInput>;
};


export type MutationCreatePostSummariesArgs = {
  data: Array<PostSummaryCreateInput>;
};


export type MutationCreatePostSummaryArgs = {
  data: PostSummaryCreateInput;
};


export type MutationCreatePostWarningArgs = {
  data: PostWarningCreateInput;
};


export type MutationCreatePostWarningsArgs = {
  data: Array<PostWarningCreateInput>;
};


export type MutationCreatePostsArgs = {
  data: Array<PostCreateInput>;
};


export type MutationCreateReactionArgs = {
  data: ReactionCreateInput;
};


export type MutationCreateReactionsArgs = {
  data: Array<ReactionCreateInput>;
};


export type MutationCreateSubjectArgs = {
  data: SubjectCreateInput;
};


export type MutationCreateSubjectsArgs = {
  data: Array<SubjectCreateInput>;
};


export type MutationCreateUserArgs = {
  data: UserCreateInput;
};


export type MutationCreateUsersArgs = {
  data: Array<UserCreateInput>;
};


export type MutationDeleteAttachedWarningArgs = {
  where: AttachedWarningWhereUniqueInput;
};


export type MutationDeleteAttachedWarningsArgs = {
  where: Array<AttachedWarningWhereUniqueInput>;
};


export type MutationDeleteContactArgs = {
  where: ContactWhereUniqueInput;
};


export type MutationDeleteContactBehaviorArgs = {
  where: ContactBehaviorWhereUniqueInput;
};


export type MutationDeleteContactBehaviorsArgs = {
  where: Array<ContactBehaviorWhereUniqueInput>;
};


export type MutationDeleteContactRelevanceArgs = {
  where: ContactRelevanceWhereUniqueInput;
};


export type MutationDeleteContactRelevancesArgs = {
  where: Array<ContactRelevanceWhereUniqueInput>;
};


export type MutationDeleteContactsArgs = {
  where: Array<ContactWhereUniqueInput>;
};


export type MutationDeleteContentWarningArgs = {
  where: ContentWarningWhereUniqueInput;
};


export type MutationDeleteContentWarningsArgs = {
  where: Array<ContentWarningWhereUniqueInput>;
};


export type MutationDeleteImpactArgs = {
  where: ImpactWhereUniqueInput;
};


export type MutationDeleteImpactsArgs = {
  where: Array<ImpactWhereUniqueInput>;
};


export type MutationDeletePageArgs = {
  where: PageWhereUniqueInput;
};


export type MutationDeletePagesArgs = {
  where: Array<PageWhereUniqueInput>;
};


export type MutationDeletePostArgs = {
  where: PostWhereUniqueInput;
};


export type MutationDeletePostArchiveArgs = {
  where: PostArchiveWhereUniqueInput;
};


export type MutationDeletePostArchiveItemArgs = {
  where: PostArchiveItemWhereUniqueInput;
};


export type MutationDeletePostArchiveItemsArgs = {
  where: Array<PostArchiveItemWhereUniqueInput>;
};


export type MutationDeletePostArchivesArgs = {
  where: Array<PostArchiveWhereUniqueInput>;
};


export type MutationDeletePostCaptureArgs = {
  where: PostCaptureWhereUniqueInput;
};


export type MutationDeletePostCapturesArgs = {
  where: Array<PostCaptureWhereUniqueInput>;
};


export type MutationDeletePostImpactArgs = {
  where: PostImpactWhereUniqueInput;
};


export type MutationDeletePostImpactsArgs = {
  where: Array<PostImpactWhereUniqueInput>;
};


export type MutationDeletePostPreviewImageArgs = {
  where: PostPreviewImageWhereUniqueInput;
};


export type MutationDeletePostPreviewImagesArgs = {
  where: Array<PostPreviewImageWhereUniqueInput>;
};


export type MutationDeletePostSubjectArgs = {
  where: PostSubjectWhereUniqueInput;
};


export type MutationDeletePostSubjectsArgs = {
  where: Array<PostSubjectWhereUniqueInput>;
};


export type MutationDeletePostSummariesArgs = {
  where: Array<PostSummaryWhereUniqueInput>;
};


export type MutationDeletePostSummaryArgs = {
  where: PostSummaryWhereUniqueInput;
};


export type MutationDeletePostWarningArgs = {
  where: PostWarningWhereUniqueInput;
};


export type MutationDeletePostWarningsArgs = {
  where: Array<PostWarningWhereUniqueInput>;
};


export type MutationDeletePostsArgs = {
  where: Array<PostWhereUniqueInput>;
};


export type MutationDeleteReactionArgs = {
  where: ReactionWhereUniqueInput;
};


export type MutationDeleteReactionsArgs = {
  where: Array<ReactionWhereUniqueInput>;
};


export type MutationDeleteSubjectArgs = {
  where: SubjectWhereUniqueInput;
};


export type MutationDeleteSubjectsArgs = {
  where: Array<SubjectWhereUniqueInput>;
};


export type MutationDeleteUserArgs = {
  where: UserWhereUniqueInput;
};


export type MutationDeleteUsersArgs = {
  where: Array<UserWhereUniqueInput>;
};


export type MutationUpdateAttachedWarningArgs = {
  data: AttachedWarningUpdateInput;
  where: AttachedWarningWhereUniqueInput;
};


export type MutationUpdateAttachedWarningsArgs = {
  data: Array<AttachedWarningUpdateArgs>;
};


export type MutationUpdateContactArgs = {
  data: ContactUpdateInput;
  where: ContactWhereUniqueInput;
};


export type MutationUpdateContactBehaviorArgs = {
  data: ContactBehaviorUpdateInput;
  where: ContactBehaviorWhereUniqueInput;
};


export type MutationUpdateContactBehaviorsArgs = {
  data: Array<ContactBehaviorUpdateArgs>;
};


export type MutationUpdateContactRelevanceArgs = {
  data: ContactRelevanceUpdateInput;
  where: ContactRelevanceWhereUniqueInput;
};


export type MutationUpdateContactRelevancesArgs = {
  data: Array<ContactRelevanceUpdateArgs>;
};


export type MutationUpdateContactsArgs = {
  data: Array<ContactUpdateArgs>;
};


export type MutationUpdateContentWarningArgs = {
  data: ContentWarningUpdateInput;
  where: ContentWarningWhereUniqueInput;
};


export type MutationUpdateContentWarningsArgs = {
  data: Array<ContentWarningUpdateArgs>;
};


export type MutationUpdateImpactArgs = {
  data: ImpactUpdateInput;
  where: ImpactWhereUniqueInput;
};


export type MutationUpdateImpactsArgs = {
  data: Array<ImpactUpdateArgs>;
};


export type MutationUpdatePageArgs = {
  data: PageUpdateInput;
  where: PageWhereUniqueInput;
};


export type MutationUpdatePagesArgs = {
  data: Array<PageUpdateArgs>;
};


export type MutationUpdatePostArgs = {
  data: PostUpdateInput;
  where: PostWhereUniqueInput;
};


export type MutationUpdatePostArchiveArgs = {
  data: PostArchiveUpdateInput;
  where: PostArchiveWhereUniqueInput;
};


export type MutationUpdatePostArchiveItemArgs = {
  data: PostArchiveItemUpdateInput;
  where: PostArchiveItemWhereUniqueInput;
};


export type MutationUpdatePostArchiveItemsArgs = {
  data: Array<PostArchiveItemUpdateArgs>;
};


export type MutationUpdatePostArchivesArgs = {
  data: Array<PostArchiveUpdateArgs>;
};


export type MutationUpdatePostCaptureArgs = {
  data: PostCaptureUpdateInput;
  where: PostCaptureWhereUniqueInput;
};


export type MutationUpdatePostCapturesArgs = {
  data: Array<PostCaptureUpdateArgs>;
};


export type MutationUpdatePostImpactArgs = {
  data: PostImpactUpdateInput;
  where: PostImpactWhereUniqueInput;
};


export type MutationUpdatePostImpactsArgs = {
  data: Array<PostImpactUpdateArgs>;
};


export type MutationUpdatePostPreviewImageArgs = {
  data: PostPreviewImageUpdateInput;
  where: PostPreviewImageWhereUniqueInput;
};


export type MutationUpdatePostPreviewImagesArgs = {
  data: Array<PostPreviewImageUpdateArgs>;
};


export type MutationUpdatePostSubjectArgs = {
  data: PostSubjectUpdateInput;
  where: PostSubjectWhereUniqueInput;
};


export type MutationUpdatePostSubjectsArgs = {
  data: Array<PostSubjectUpdateArgs>;
};


export type MutationUpdatePostSummariesArgs = {
  data: Array<PostSummaryUpdateArgs>;
};


export type MutationUpdatePostSummaryArgs = {
  data: PostSummaryUpdateInput;
  where: PostSummaryWhereUniqueInput;
};


export type MutationUpdatePostWarningArgs = {
  data: PostWarningUpdateInput;
  where: PostWarningWhereUniqueInput;
};


export type MutationUpdatePostWarningsArgs = {
  data: Array<PostWarningUpdateArgs>;
};


export type MutationUpdatePostsArgs = {
  data: Array<PostUpdateArgs>;
};


export type MutationUpdateReactionArgs = {
  data: ReactionUpdateInput;
  where: ReactionWhereUniqueInput;
};


export type MutationUpdateReactionsArgs = {
  data: Array<ReactionUpdateArgs>;
};


export type MutationUpdateSubjectArgs = {
  data: SubjectUpdateInput;
  where: SubjectWhereUniqueInput;
};


export type MutationUpdateSubjectsArgs = {
  data: Array<SubjectUpdateArgs>;
};


export type MutationUpdateUserArgs = {
  data: UserUpdateInput;
  where: UserWhereUniqueInput;
};


export type MutationUpdateUsersArgs = {
  data: Array<UserUpdateArgs>;
};

export type NestedStringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type NestedStringNullableFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export enum OrderDirection {
  ASC = 'asc',
  DESC = 'desc'
}

export type Page = {
  __typename?: 'Page';
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  platform?: Maybe<Scalars['String']>;
  posts?: Maybe<Array<Post>>;
  postsCount?: Maybe<Scalars['Int']>;
  profileOf?: Maybe<Contact>;
  url?: Maybe<Scalars['String']>;
};


export type PagePostsArgs = {
  orderBy?: Array<PostOrderByInput>;
  skip?: Scalars['Int'];
  take?: InputMaybe<Scalars['Int']>;
  where?: PostWhereInput;
};


export type PagePostsCountArgs = {
  where?: PostWhereInput;
};

export type PageCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  name?: InputMaybe<Scalars['String']>;
  platform?: InputMaybe<Scalars['String']>;
  posts?: InputMaybe<PostRelateToManyForCreateInput>;
  profileOf?: InputMaybe<ContactRelateToOneForCreateInput>;
  url?: InputMaybe<Scalars['String']>;
};

export type PageManyRelationFilter = {
  every?: InputMaybe<PageWhereInput>;
  none?: InputMaybe<PageWhereInput>;
  some?: InputMaybe<PageWhereInput>;
};

export type PageOrderByInput = {
  createdAt?: InputMaybe<OrderDirection>;
  id?: InputMaybe<OrderDirection>;
  name?: InputMaybe<OrderDirection>;
  platform?: InputMaybe<OrderDirection>;
  url?: InputMaybe<OrderDirection>;
};

export type PageRelateToManyForCreateInput = {
  connect?: InputMaybe<Array<PageWhereUniqueInput>>;
  create?: InputMaybe<Array<PageCreateInput>>;
};

export type PageRelateToManyForUpdateInput = {
  connect?: InputMaybe<Array<PageWhereUniqueInput>>;
  create?: InputMaybe<Array<PageCreateInput>>;
  disconnect?: InputMaybe<Array<PageWhereUniqueInput>>;
  set?: InputMaybe<Array<PageWhereUniqueInput>>;
};

export type PageRelateToOneForCreateInput = {
  connect?: InputMaybe<PageWhereUniqueInput>;
  create?: InputMaybe<PageCreateInput>;
};

export type PageRelateToOneForUpdateInput = {
  connect?: InputMaybe<PageWhereUniqueInput>;
  create?: InputMaybe<PageCreateInput>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
};

export type PageUpdateArgs = {
  data: PageUpdateInput;
  where: PageWhereUniqueInput;
};

export type PageUpdateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  name?: InputMaybe<Scalars['String']>;
  platform?: InputMaybe<Scalars['String']>;
  posts?: InputMaybe<PostRelateToManyForUpdateInput>;
  profileOf?: InputMaybe<ContactRelateToOneForUpdateInput>;
  url?: InputMaybe<Scalars['String']>;
};

export type PageWhereInput = {
  AND?: InputMaybe<Array<PageWhereInput>>;
  NOT?: InputMaybe<Array<PageWhereInput>>;
  OR?: InputMaybe<Array<PageWhereInput>>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<IdFilter>;
  name?: InputMaybe<StringFilter>;
  platform?: InputMaybe<StringFilter>;
  posts?: InputMaybe<PostManyRelationFilter>;
  profileOf?: InputMaybe<ContactWhereInput>;
  url?: InputMaybe<StringFilter>;
};

export type PageWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
  url?: InputMaybe<Scalars['String']>;
};

export type PasswordState = {
  __typename?: 'PasswordState';
  isSet: Scalars['Boolean'];
};

export type Post = {
  __typename?: 'Post';
  archive?: Maybe<PostArchive>;
  author?: Maybe<Contact>;
  children?: Maybe<Array<Post>>;
  childrenCount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  impact?: Maybe<Array<PostImpact>>;
  impactCount?: Maybe<Scalars['Int']>;
  label?: Maybe<Scalars['String']>;
  page?: Maybe<Page>;
  parent?: Maybe<Post>;
  platform?: Maybe<Scalars['String']>;
  postType?: Maybe<PostPostTypeType>;
  posted?: Maybe<Scalars['DateTime']>;
  preview?: Maybe<PostPreviewImage>;
  reactions?: Maybe<Array<Reaction>>;
  reactionsCount?: Maybe<Scalars['Int']>;
  ref?: Maybe<Scalars['String']>;
  saved?: Maybe<Array<PostCapture>>;
  savedCount?: Maybe<Scalars['Int']>;
  subjects?: Maybe<Array<PostSubject>>;
  subjectsCount?: Maybe<Scalars['Int']>;
  summary?: Maybe<Array<PostSummary>>;
  summaryCount?: Maybe<Scalars['Int']>;
  url?: Maybe<Scalars['String']>;
  urlVisibility?: Maybe<PostUrlVisibilityType>;
  visibility?: Maybe<PostVisibilityType>;
  warnings?: Maybe<Array<PostWarning>>;
  warningsCount?: Maybe<Scalars['Int']>;
};


export type PostChildrenArgs = {
  orderBy?: Array<PostOrderByInput>;
  skip?: Scalars['Int'];
  take?: InputMaybe<Scalars['Int']>;
  where?: PostWhereInput;
};


export type PostChildrenCountArgs = {
  where?: PostWhereInput;
};


export type PostImpactArgs = {
  orderBy?: Array<PostImpactOrderByInput>;
  skip?: Scalars['Int'];
  take?: InputMaybe<Scalars['Int']>;
  where?: PostImpactWhereInput;
};


export type PostImpactCountArgs = {
  where?: PostImpactWhereInput;
};


export type PostReactionsArgs = {
  orderBy?: Array<ReactionOrderByInput>;
  skip?: Scalars['Int'];
  take?: InputMaybe<Scalars['Int']>;
  where?: ReactionWhereInput;
};


export type PostReactionsCountArgs = {
  where?: ReactionWhereInput;
};


export type PostSavedArgs = {
  orderBy?: Array<PostCaptureOrderByInput>;
  skip?: Scalars['Int'];
  take?: InputMaybe<Scalars['Int']>;
  where?: PostCaptureWhereInput;
};


export type PostSavedCountArgs = {
  where?: PostCaptureWhereInput;
};


export type PostSubjectsArgs = {
  orderBy?: Array<PostSubjectOrderByInput>;
  skip?: Scalars['Int'];
  take?: InputMaybe<Scalars['Int']>;
  where?: PostSubjectWhereInput;
};


export type PostSubjectsCountArgs = {
  where?: PostSubjectWhereInput;
};


export type PostSummaryArgs = {
  orderBy?: Array<PostSummaryOrderByInput>;
  skip?: Scalars['Int'];
  take?: InputMaybe<Scalars['Int']>;
  where?: PostSummaryWhereInput;
};


export type PostSummaryCountArgs = {
  where?: PostSummaryWhereInput;
};


export type PostWarningsArgs = {
  orderBy?: Array<PostWarningOrderByInput>;
  skip?: Scalars['Int'];
  take?: InputMaybe<Scalars['Int']>;
  where?: PostWarningWhereInput;
};


export type PostWarningsCountArgs = {
  where?: PostWarningWhereInput;
};

export type PostArchive = {
  __typename?: 'PostArchive';
  id: Scalars['ID'];
  items?: Maybe<Array<PostArchiveItem>>;
  itemsCount?: Maybe<Scalars['Int']>;
  post?: Maybe<Post>;
};


export type PostArchiveItemsArgs = {
  orderBy?: Array<PostArchiveItemOrderByInput>;
  skip?: Scalars['Int'];
  take?: InputMaybe<Scalars['Int']>;
  where?: PostArchiveItemWhereInput;
};


export type PostArchiveItemsCountArgs = {
  where?: PostArchiveItemWhereInput;
};

export type PostArchiveCreateInput = {
  items?: InputMaybe<PostArchiveItemRelateToManyForCreateInput>;
  post?: InputMaybe<PostRelateToOneForCreateInput>;
};

export type PostArchiveItem = {
  __typename?: 'PostArchiveItem';
  archive?: Maybe<PostArchive>;
  complete?: Maybe<ImageFieldOutput>;
  fullRedacted?: Maybe<ImageFieldOutput>;
  id: Scalars['ID'];
  index?: Maybe<Scalars['Int']>;
  nameRedacted?: Maybe<ImageFieldOutput>;
  type?: Maybe<PostArchiveItemTypeType>;
};

export type PostArchiveItemCreateInput = {
  archive?: InputMaybe<PostArchiveRelateToOneForCreateInput>;
  complete?: InputMaybe<ImageFieldInput>;
  fullRedacted?: InputMaybe<ImageFieldInput>;
  index?: InputMaybe<Scalars['Int']>;
  nameRedacted?: InputMaybe<ImageFieldInput>;
  type?: InputMaybe<PostArchiveItemTypeType>;
};

export type PostArchiveItemManyRelationFilter = {
  every?: InputMaybe<PostArchiveItemWhereInput>;
  none?: InputMaybe<PostArchiveItemWhereInput>;
  some?: InputMaybe<PostArchiveItemWhereInput>;
};

export type PostArchiveItemOrderByInput = {
  id?: InputMaybe<OrderDirection>;
  index?: InputMaybe<OrderDirection>;
  type?: InputMaybe<OrderDirection>;
};

export type PostArchiveItemRelateToManyForCreateInput = {
  connect?: InputMaybe<Array<PostArchiveItemWhereUniqueInput>>;
  create?: InputMaybe<Array<PostArchiveItemCreateInput>>;
};

export type PostArchiveItemRelateToManyForUpdateInput = {
  connect?: InputMaybe<Array<PostArchiveItemWhereUniqueInput>>;
  create?: InputMaybe<Array<PostArchiveItemCreateInput>>;
  disconnect?: InputMaybe<Array<PostArchiveItemWhereUniqueInput>>;
  set?: InputMaybe<Array<PostArchiveItemWhereUniqueInput>>;
};

export enum PostArchiveItemTypeType {
  SCREENSHOT = 'screenshot'
}

export type PostArchiveItemTypeTypeNullableFilter = {
  equals?: InputMaybe<PostArchiveItemTypeType>;
  in?: InputMaybe<Array<PostArchiveItemTypeType>>;
  not?: InputMaybe<PostArchiveItemTypeTypeNullableFilter>;
  notIn?: InputMaybe<Array<PostArchiveItemTypeType>>;
};

export type PostArchiveItemUpdateArgs = {
  data: PostArchiveItemUpdateInput;
  where: PostArchiveItemWhereUniqueInput;
};

export type PostArchiveItemUpdateInput = {
  archive?: InputMaybe<PostArchiveRelateToOneForUpdateInput>;
  complete?: InputMaybe<ImageFieldInput>;
  fullRedacted?: InputMaybe<ImageFieldInput>;
  index?: InputMaybe<Scalars['Int']>;
  nameRedacted?: InputMaybe<ImageFieldInput>;
  type?: InputMaybe<PostArchiveItemTypeType>;
};

export type PostArchiveItemWhereInput = {
  AND?: InputMaybe<Array<PostArchiveItemWhereInput>>;
  NOT?: InputMaybe<Array<PostArchiveItemWhereInput>>;
  OR?: InputMaybe<Array<PostArchiveItemWhereInput>>;
  archive?: InputMaybe<PostArchiveWhereInput>;
  id?: InputMaybe<IdFilter>;
  index?: InputMaybe<IntNullableFilter>;
  type?: InputMaybe<PostArchiveItemTypeTypeNullableFilter>;
};

export type PostArchiveItemWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type PostArchiveOrderByInput = {
  id?: InputMaybe<OrderDirection>;
};

export type PostArchiveRelateToOneForCreateInput = {
  connect?: InputMaybe<PostArchiveWhereUniqueInput>;
  create?: InputMaybe<PostArchiveCreateInput>;
};

export type PostArchiveRelateToOneForUpdateInput = {
  connect?: InputMaybe<PostArchiveWhereUniqueInput>;
  create?: InputMaybe<PostArchiveCreateInput>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
};

export type PostArchiveUpdateArgs = {
  data: PostArchiveUpdateInput;
  where: PostArchiveWhereUniqueInput;
};

export type PostArchiveUpdateInput = {
  items?: InputMaybe<PostArchiveItemRelateToManyForUpdateInput>;
  post?: InputMaybe<PostRelateToOneForUpdateInput>;
};

export type PostArchiveWhereInput = {
  AND?: InputMaybe<Array<PostArchiveWhereInput>>;
  NOT?: InputMaybe<Array<PostArchiveWhereInput>>;
  OR?: InputMaybe<Array<PostArchiveWhereInput>>;
  id?: InputMaybe<IdFilter>;
  items?: InputMaybe<PostArchiveItemManyRelationFilter>;
  post?: InputMaybe<PostWhereInput>;
};

export type PostArchiveWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type PostCapture = {
  __typename?: 'PostCapture';
  censoredContent?: Maybe<ImageFieldOutput>;
  censoredNames?: Maybe<ImageFieldOutput>;
  censoredNamesAndContent?: Maybe<ImageFieldOutput>;
  complete?: Maybe<ImageFieldOutput>;
  completeVisibility?: Maybe<PostCaptureCompleteVisibilityType>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  includesNames?: Maybe<Array<Contact>>;
  includesNamesCount?: Maybe<Scalars['Int']>;
  post?: Maybe<Post>;
  visibility?: Maybe<PostCaptureVisibilityType>;
  warnings?: Maybe<Array<AttachedWarning>>;
  warningsCount?: Maybe<Scalars['Int']>;
};


export type PostCaptureIncludesNamesArgs = {
  orderBy?: Array<ContactOrderByInput>;
  skip?: Scalars['Int'];
  take?: InputMaybe<Scalars['Int']>;
  where?: ContactWhereInput;
};


export type PostCaptureIncludesNamesCountArgs = {
  where?: ContactWhereInput;
};


export type PostCaptureWarningsArgs = {
  orderBy?: Array<AttachedWarningOrderByInput>;
  skip?: Scalars['Int'];
  take?: InputMaybe<Scalars['Int']>;
  where?: AttachedWarningWhereInput;
};


export type PostCaptureWarningsCountArgs = {
  where?: AttachedWarningWhereInput;
};

export enum PostCaptureCompleteVisibilityType {
  ADMIN_ONLY = 'admin_only',
  CONTRIBUTORS = 'contributors',
  KNOWN_CONTRIBUTORS = 'known_contributors',
  MODERATORS = 'moderators',
  PUBLIC = 'public',
  TRUSTED_CONTRIBUTORS = 'trusted_contributors'
}

export type PostCaptureCompleteVisibilityTypeNullableFilter = {
  equals?: InputMaybe<PostCaptureCompleteVisibilityType>;
  in?: InputMaybe<Array<PostCaptureCompleteVisibilityType>>;
  not?: InputMaybe<PostCaptureCompleteVisibilityTypeNullableFilter>;
  notIn?: InputMaybe<Array<PostCaptureCompleteVisibilityType>>;
};

export type PostCaptureCreateInput = {
  censoredContent?: InputMaybe<ImageFieldInput>;
  censoredNames?: InputMaybe<ImageFieldInput>;
  censoredNamesAndContent?: InputMaybe<ImageFieldInput>;
  complete?: InputMaybe<ImageFieldInput>;
  completeVisibility?: InputMaybe<PostCaptureCompleteVisibilityType>;
  description?: InputMaybe<Scalars['String']>;
  includesNames?: InputMaybe<ContactRelateToManyForCreateInput>;
  post?: InputMaybe<PostRelateToOneForCreateInput>;
  visibility?: InputMaybe<PostCaptureVisibilityType>;
  warnings?: InputMaybe<AttachedWarningRelateToManyForCreateInput>;
};

export type PostCaptureManyRelationFilter = {
  every?: InputMaybe<PostCaptureWhereInput>;
  none?: InputMaybe<PostCaptureWhereInput>;
  some?: InputMaybe<PostCaptureWhereInput>;
};

export type PostCaptureOrderByInput = {
  completeVisibility?: InputMaybe<OrderDirection>;
  description?: InputMaybe<OrderDirection>;
  id?: InputMaybe<OrderDirection>;
  visibility?: InputMaybe<OrderDirection>;
};

export type PostCaptureRelateToManyForCreateInput = {
  connect?: InputMaybe<Array<PostCaptureWhereUniqueInput>>;
  create?: InputMaybe<Array<PostCaptureCreateInput>>;
};

export type PostCaptureRelateToManyForUpdateInput = {
  connect?: InputMaybe<Array<PostCaptureWhereUniqueInput>>;
  create?: InputMaybe<Array<PostCaptureCreateInput>>;
  disconnect?: InputMaybe<Array<PostCaptureWhereUniqueInput>>;
  set?: InputMaybe<Array<PostCaptureWhereUniqueInput>>;
};

export type PostCaptureUpdateArgs = {
  data: PostCaptureUpdateInput;
  where: PostCaptureWhereUniqueInput;
};

export type PostCaptureUpdateInput = {
  censoredContent?: InputMaybe<ImageFieldInput>;
  censoredNames?: InputMaybe<ImageFieldInput>;
  censoredNamesAndContent?: InputMaybe<ImageFieldInput>;
  complete?: InputMaybe<ImageFieldInput>;
  completeVisibility?: InputMaybe<PostCaptureCompleteVisibilityType>;
  description?: InputMaybe<Scalars['String']>;
  includesNames?: InputMaybe<ContactRelateToManyForUpdateInput>;
  post?: InputMaybe<PostRelateToOneForUpdateInput>;
  visibility?: InputMaybe<PostCaptureVisibilityType>;
  warnings?: InputMaybe<AttachedWarningRelateToManyForUpdateInput>;
};

export enum PostCaptureVisibilityType {
  ADMIN_ONLY = 'admin_only',
  CONTRIBUTORS = 'contributors',
  KNOWN_CONTRIBUTORS = 'known_contributors',
  MODERATORS = 'moderators',
  PUBLIC = 'public',
  TRUSTED_CONTRIBUTORS = 'trusted_contributors'
}

export type PostCaptureVisibilityTypeNullableFilter = {
  equals?: InputMaybe<PostCaptureVisibilityType>;
  in?: InputMaybe<Array<PostCaptureVisibilityType>>;
  not?: InputMaybe<PostCaptureVisibilityTypeNullableFilter>;
  notIn?: InputMaybe<Array<PostCaptureVisibilityType>>;
};

export type PostCaptureWhereInput = {
  AND?: InputMaybe<Array<PostCaptureWhereInput>>;
  NOT?: InputMaybe<Array<PostCaptureWhereInput>>;
  OR?: InputMaybe<Array<PostCaptureWhereInput>>;
  completeVisibility?: InputMaybe<PostCaptureCompleteVisibilityTypeNullableFilter>;
  description?: InputMaybe<StringFilter>;
  id?: InputMaybe<IdFilter>;
  includesNames?: InputMaybe<ContactManyRelationFilter>;
  post?: InputMaybe<PostWhereInput>;
  visibility?: InputMaybe<PostCaptureVisibilityTypeNullableFilter>;
  warnings?: InputMaybe<AttachedWarningManyRelationFilter>;
};

export type PostCaptureWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type PostCreateInput = {
  archive?: InputMaybe<PostArchiveRelateToOneForCreateInput>;
  author?: InputMaybe<ContactRelateToOneForCreateInput>;
  children?: InputMaybe<PostRelateToManyForCreateInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  impact?: InputMaybe<PostImpactRelateToManyForCreateInput>;
  page?: InputMaybe<PageRelateToOneForCreateInput>;
  parent?: InputMaybe<PostRelateToOneForCreateInput>;
  platform?: InputMaybe<Scalars['String']>;
  postType?: InputMaybe<PostPostTypeType>;
  posted?: InputMaybe<Scalars['DateTime']>;
  preview?: InputMaybe<PostPreviewImageRelateToOneForCreateInput>;
  reactions?: InputMaybe<ReactionRelateToManyForCreateInput>;
  ref?: InputMaybe<Scalars['String']>;
  saved?: InputMaybe<PostCaptureRelateToManyForCreateInput>;
  subjects?: InputMaybe<PostSubjectRelateToManyForCreateInput>;
  summary?: InputMaybe<PostSummaryRelateToManyForCreateInput>;
  url?: InputMaybe<Scalars['String']>;
  urlVisibility?: InputMaybe<PostUrlVisibilityType>;
  visibility?: InputMaybe<PostVisibilityType>;
  warnings?: InputMaybe<PostWarningRelateToManyForCreateInput>;
};

export type PostImpact = {
  __typename?: 'PostImpact';
  conviction?: Maybe<PostImpactConvictionType>;
  id: Scalars['ID'];
  impact?: Maybe<Impact>;
  impactExtent?: Maybe<PostImpactImpactExtentType>;
  label?: Maybe<Scalars['String']>;
  motive?: Maybe<PostImpactMotiveType>;
  notes?: Maybe<Scalars['String']>;
  notesVisibility?: Maybe<PostImpactNotesVisibilityType>;
  post?: Maybe<Post>;
  visibility?: Maybe<PostImpactVisibilityType>;
};

export enum PostImpactConvictionType {
  EMPTY = 'empty',
  MODERATE = 'moderate',
  SOLID = 'solid',
  STRONG = 'strong',
  WEAK = 'weak'
}

export type PostImpactConvictionTypeNullableFilter = {
  equals?: InputMaybe<PostImpactConvictionType>;
  in?: InputMaybe<Array<PostImpactConvictionType>>;
  not?: InputMaybe<PostImpactConvictionTypeNullableFilter>;
  notIn?: InputMaybe<Array<PostImpactConvictionType>>;
};

export type PostImpactCreateInput = {
  conviction?: InputMaybe<PostImpactConvictionType>;
  impact?: InputMaybe<ImpactRelateToOneForCreateInput>;
  impactExtent?: InputMaybe<PostImpactImpactExtentType>;
  motive?: InputMaybe<PostImpactMotiveType>;
  notes?: InputMaybe<Scalars['String']>;
  notesVisibility?: InputMaybe<PostImpactNotesVisibilityType>;
  post?: InputMaybe<PostRelateToOneForCreateInput>;
  visibility?: InputMaybe<PostImpactVisibilityType>;
};

export enum PostImpactImpactExtentType {
  EMPTY = 'empty',
  MODERATE = 'moderate',
  SOLID = 'solid',
  STRONG = 'strong',
  WEAK = 'weak'
}

export type PostImpactImpactExtentTypeNullableFilter = {
  equals?: InputMaybe<PostImpactImpactExtentType>;
  in?: InputMaybe<Array<PostImpactImpactExtentType>>;
  not?: InputMaybe<PostImpactImpactExtentTypeNullableFilter>;
  notIn?: InputMaybe<Array<PostImpactImpactExtentType>>;
};

export type PostImpactManyRelationFilter = {
  every?: InputMaybe<PostImpactWhereInput>;
  none?: InputMaybe<PostImpactWhereInput>;
  some?: InputMaybe<PostImpactWhereInput>;
};

export enum PostImpactMotiveType {
  BLUNDER = 'blunder',
  CURIOUS = 'curious',
  HATE = 'hate',
  STRONG_SUPPORTER = 'strong_supporter',
  SUPPORTER = 'supporter'
}

export type PostImpactMotiveTypeNullableFilter = {
  equals?: InputMaybe<PostImpactMotiveType>;
  in?: InputMaybe<Array<PostImpactMotiveType>>;
  not?: InputMaybe<PostImpactMotiveTypeNullableFilter>;
  notIn?: InputMaybe<Array<PostImpactMotiveType>>;
};

export enum PostImpactNotesVisibilityType {
  ADMIN_ONLY = 'admin_only',
  CONTRIBUTORS = 'contributors',
  KNOWN_CONTRIBUTORS = 'known_contributors',
  MODERATORS = 'moderators',
  PUBLIC = 'public',
  TRUSTED_CONTRIBUTORS = 'trusted_contributors'
}

export type PostImpactNotesVisibilityTypeNullableFilter = {
  equals?: InputMaybe<PostImpactNotesVisibilityType>;
  in?: InputMaybe<Array<PostImpactNotesVisibilityType>>;
  not?: InputMaybe<PostImpactNotesVisibilityTypeNullableFilter>;
  notIn?: InputMaybe<Array<PostImpactNotesVisibilityType>>;
};

export type PostImpactOrderByInput = {
  conviction?: InputMaybe<OrderDirection>;
  id?: InputMaybe<OrderDirection>;
  impactExtent?: InputMaybe<OrderDirection>;
  motive?: InputMaybe<OrderDirection>;
  notes?: InputMaybe<OrderDirection>;
  notesVisibility?: InputMaybe<OrderDirection>;
  visibility?: InputMaybe<OrderDirection>;
};

export type PostImpactRelateToManyForCreateInput = {
  connect?: InputMaybe<Array<PostImpactWhereUniqueInput>>;
  create?: InputMaybe<Array<PostImpactCreateInput>>;
};

export type PostImpactRelateToManyForUpdateInput = {
  connect?: InputMaybe<Array<PostImpactWhereUniqueInput>>;
  create?: InputMaybe<Array<PostImpactCreateInput>>;
  disconnect?: InputMaybe<Array<PostImpactWhereUniqueInput>>;
  set?: InputMaybe<Array<PostImpactWhereUniqueInput>>;
};

export type PostImpactUpdateArgs = {
  data: PostImpactUpdateInput;
  where: PostImpactWhereUniqueInput;
};

export type PostImpactUpdateInput = {
  conviction?: InputMaybe<PostImpactConvictionType>;
  impact?: InputMaybe<ImpactRelateToOneForUpdateInput>;
  impactExtent?: InputMaybe<PostImpactImpactExtentType>;
  motive?: InputMaybe<PostImpactMotiveType>;
  notes?: InputMaybe<Scalars['String']>;
  notesVisibility?: InputMaybe<PostImpactNotesVisibilityType>;
  post?: InputMaybe<PostRelateToOneForUpdateInput>;
  visibility?: InputMaybe<PostImpactVisibilityType>;
};

export enum PostImpactVisibilityType {
  ADMIN_ONLY = 'admin_only',
  CONTRIBUTORS = 'contributors',
  KNOWN_CONTRIBUTORS = 'known_contributors',
  MODERATORS = 'moderators',
  PUBLIC = 'public',
  TRUSTED_CONTRIBUTORS = 'trusted_contributors'
}

export type PostImpactVisibilityTypeNullableFilter = {
  equals?: InputMaybe<PostImpactVisibilityType>;
  in?: InputMaybe<Array<PostImpactVisibilityType>>;
  not?: InputMaybe<PostImpactVisibilityTypeNullableFilter>;
  notIn?: InputMaybe<Array<PostImpactVisibilityType>>;
};

export type PostImpactWhereInput = {
  AND?: InputMaybe<Array<PostImpactWhereInput>>;
  NOT?: InputMaybe<Array<PostImpactWhereInput>>;
  OR?: InputMaybe<Array<PostImpactWhereInput>>;
  conviction?: InputMaybe<PostImpactConvictionTypeNullableFilter>;
  id?: InputMaybe<IdFilter>;
  impact?: InputMaybe<ImpactWhereInput>;
  impactExtent?: InputMaybe<PostImpactImpactExtentTypeNullableFilter>;
  motive?: InputMaybe<PostImpactMotiveTypeNullableFilter>;
  notes?: InputMaybe<StringFilter>;
  notesVisibility?: InputMaybe<PostImpactNotesVisibilityTypeNullableFilter>;
  post?: InputMaybe<PostWhereInput>;
  visibility?: InputMaybe<PostImpactVisibilityTypeNullableFilter>;
};

export type PostImpactWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type PostManyRelationFilter = {
  every?: InputMaybe<PostWhereInput>;
  none?: InputMaybe<PostWhereInput>;
  some?: InputMaybe<PostWhereInput>;
};

export type PostOrderByInput = {
  createdAt?: InputMaybe<OrderDirection>;
  id?: InputMaybe<OrderDirection>;
  platform?: InputMaybe<OrderDirection>;
  postType?: InputMaybe<OrderDirection>;
  posted?: InputMaybe<OrderDirection>;
  ref?: InputMaybe<OrderDirection>;
  url?: InputMaybe<OrderDirection>;
  urlVisibility?: InputMaybe<OrderDirection>;
  visibility?: InputMaybe<OrderDirection>;
};

export enum PostPostTypeType {
  COMMENT = 'comment',
  FUNDRAISER = 'fundraiser',
  FUNDRAISER_DONATE = 'fundraiser_donate',
  FUNDRAISER_SHARE = 'fundraiser_share',
  LEGAL_FILING = 'legal_filing',
  LEGAL_TESTIMONY = 'legal_testimony',
  PETITION = 'petition',
  PETITION_SHARE = 'petition_share',
  PETITION_SIGN = 'petition_sign',
  SHARE = 'share',
  SHARE_COMMENTARY = 'share_commentary',
  SOCIAL = 'social'
}

export type PostPostTypeTypeNullableFilter = {
  equals?: InputMaybe<PostPostTypeType>;
  in?: InputMaybe<Array<PostPostTypeType>>;
  not?: InputMaybe<PostPostTypeTypeNullableFilter>;
  notIn?: InputMaybe<Array<PostPostTypeType>>;
};

export type PostPreviewImage = {
  __typename?: 'PostPreviewImage';
  default?: Maybe<ImageFieldOutput>;
  defaultVisibility?: Maybe<PostPreviewImageDefaultVisibilityType>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  includesNames?: Maybe<Array<Contact>>;
  includesNamesCount?: Maybe<Scalars['Int']>;
  nameFree?: Maybe<ImageFieldOutput>;
  post?: Maybe<Post>;
  visibility?: Maybe<PostPreviewImageVisibilityType>;
  warnings?: Maybe<Array<AttachedWarning>>;
  warningsCount?: Maybe<Scalars['Int']>;
};


export type PostPreviewImageIncludesNamesArgs = {
  orderBy?: Array<ContactOrderByInput>;
  skip?: Scalars['Int'];
  take?: InputMaybe<Scalars['Int']>;
  where?: ContactWhereInput;
};


export type PostPreviewImageIncludesNamesCountArgs = {
  where?: ContactWhereInput;
};


export type PostPreviewImageWarningsArgs = {
  orderBy?: Array<AttachedWarningOrderByInput>;
  skip?: Scalars['Int'];
  take?: InputMaybe<Scalars['Int']>;
  where?: AttachedWarningWhereInput;
};


export type PostPreviewImageWarningsCountArgs = {
  where?: AttachedWarningWhereInput;
};

export type PostPreviewImageCreateInput = {
  default?: InputMaybe<ImageFieldInput>;
  defaultVisibility?: InputMaybe<PostPreviewImageDefaultVisibilityType>;
  description?: InputMaybe<Scalars['String']>;
  includesNames?: InputMaybe<ContactRelateToManyForCreateInput>;
  nameFree?: InputMaybe<ImageFieldInput>;
  post?: InputMaybe<PostRelateToOneForCreateInput>;
  visibility?: InputMaybe<PostPreviewImageVisibilityType>;
  warnings?: InputMaybe<AttachedWarningRelateToManyForCreateInput>;
};

export enum PostPreviewImageDefaultVisibilityType {
  ADMIN_ONLY = 'admin_only',
  CONTRIBUTORS = 'contributors',
  KNOWN_CONTRIBUTORS = 'known_contributors',
  MODERATORS = 'moderators',
  PUBLIC = 'public',
  TRUSTED_CONTRIBUTORS = 'trusted_contributors'
}

export type PostPreviewImageDefaultVisibilityTypeNullableFilter = {
  equals?: InputMaybe<PostPreviewImageDefaultVisibilityType>;
  in?: InputMaybe<Array<PostPreviewImageDefaultVisibilityType>>;
  not?: InputMaybe<PostPreviewImageDefaultVisibilityTypeNullableFilter>;
  notIn?: InputMaybe<Array<PostPreviewImageDefaultVisibilityType>>;
};

export type PostPreviewImageOrderByInput = {
  defaultVisibility?: InputMaybe<OrderDirection>;
  description?: InputMaybe<OrderDirection>;
  id?: InputMaybe<OrderDirection>;
  visibility?: InputMaybe<OrderDirection>;
};

export type PostPreviewImageRelateToOneForCreateInput = {
  connect?: InputMaybe<PostPreviewImageWhereUniqueInput>;
  create?: InputMaybe<PostPreviewImageCreateInput>;
};

export type PostPreviewImageRelateToOneForUpdateInput = {
  connect?: InputMaybe<PostPreviewImageWhereUniqueInput>;
  create?: InputMaybe<PostPreviewImageCreateInput>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
};

export type PostPreviewImageUpdateArgs = {
  data: PostPreviewImageUpdateInput;
  where: PostPreviewImageWhereUniqueInput;
};

export type PostPreviewImageUpdateInput = {
  default?: InputMaybe<ImageFieldInput>;
  defaultVisibility?: InputMaybe<PostPreviewImageDefaultVisibilityType>;
  description?: InputMaybe<Scalars['String']>;
  includesNames?: InputMaybe<ContactRelateToManyForUpdateInput>;
  nameFree?: InputMaybe<ImageFieldInput>;
  post?: InputMaybe<PostRelateToOneForUpdateInput>;
  visibility?: InputMaybe<PostPreviewImageVisibilityType>;
  warnings?: InputMaybe<AttachedWarningRelateToManyForUpdateInput>;
};

export enum PostPreviewImageVisibilityType {
  ADMIN_ONLY = 'admin_only',
  CONTRIBUTORS = 'contributors',
  KNOWN_CONTRIBUTORS = 'known_contributors',
  MODERATORS = 'moderators',
  PUBLIC = 'public',
  TRUSTED_CONTRIBUTORS = 'trusted_contributors'
}

export type PostPreviewImageVisibilityTypeNullableFilter = {
  equals?: InputMaybe<PostPreviewImageVisibilityType>;
  in?: InputMaybe<Array<PostPreviewImageVisibilityType>>;
  not?: InputMaybe<PostPreviewImageVisibilityTypeNullableFilter>;
  notIn?: InputMaybe<Array<PostPreviewImageVisibilityType>>;
};

export type PostPreviewImageWhereInput = {
  AND?: InputMaybe<Array<PostPreviewImageWhereInput>>;
  NOT?: InputMaybe<Array<PostPreviewImageWhereInput>>;
  OR?: InputMaybe<Array<PostPreviewImageWhereInput>>;
  defaultVisibility?: InputMaybe<PostPreviewImageDefaultVisibilityTypeNullableFilter>;
  description?: InputMaybe<StringFilter>;
  id?: InputMaybe<IdFilter>;
  includesNames?: InputMaybe<ContactManyRelationFilter>;
  post?: InputMaybe<PostWhereInput>;
  visibility?: InputMaybe<PostPreviewImageVisibilityTypeNullableFilter>;
  warnings?: InputMaybe<AttachedWarningManyRelationFilter>;
};

export type PostPreviewImageWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type PostRelateToManyForCreateInput = {
  connect?: InputMaybe<Array<PostWhereUniqueInput>>;
  create?: InputMaybe<Array<PostCreateInput>>;
};

export type PostRelateToManyForUpdateInput = {
  connect?: InputMaybe<Array<PostWhereUniqueInput>>;
  create?: InputMaybe<Array<PostCreateInput>>;
  disconnect?: InputMaybe<Array<PostWhereUniqueInput>>;
  set?: InputMaybe<Array<PostWhereUniqueInput>>;
};

export type PostRelateToOneForCreateInput = {
  connect?: InputMaybe<PostWhereUniqueInput>;
  create?: InputMaybe<PostCreateInput>;
};

export type PostRelateToOneForUpdateInput = {
  connect?: InputMaybe<PostWhereUniqueInput>;
  create?: InputMaybe<PostCreateInput>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
};

export type PostSubject = {
  __typename?: 'PostSubject';
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  matchStrength?: Maybe<PostSubjectMatchStrengthType>;
  notes?: Maybe<Scalars['String']>;
  notesVisibility?: Maybe<PostSubjectNotesVisibilityType>;
  post?: Maybe<Post>;
  subject?: Maybe<Subject>;
  visibility?: Maybe<PostSubjectVisibilityType>;
};

export type PostSubjectCreateInput = {
  matchStrength?: InputMaybe<PostSubjectMatchStrengthType>;
  notes?: InputMaybe<Scalars['String']>;
  notesVisibility?: InputMaybe<PostSubjectNotesVisibilityType>;
  post?: InputMaybe<PostRelateToOneForCreateInput>;
  subject?: InputMaybe<SubjectRelateToOneForCreateInput>;
  visibility?: InputMaybe<PostSubjectVisibilityType>;
};

export type PostSubjectManyRelationFilter = {
  every?: InputMaybe<PostSubjectWhereInput>;
  none?: InputMaybe<PostSubjectWhereInput>;
  some?: InputMaybe<PostSubjectWhereInput>;
};

export enum PostSubjectMatchStrengthType {
  EMPTY = 'empty',
  MODERATE = 'moderate',
  SOLID = 'solid',
  STRONG = 'strong',
  WEAK = 'weak'
}

export type PostSubjectMatchStrengthTypeNullableFilter = {
  equals?: InputMaybe<PostSubjectMatchStrengthType>;
  in?: InputMaybe<Array<PostSubjectMatchStrengthType>>;
  not?: InputMaybe<PostSubjectMatchStrengthTypeNullableFilter>;
  notIn?: InputMaybe<Array<PostSubjectMatchStrengthType>>;
};

export enum PostSubjectNotesVisibilityType {
  ADMIN_ONLY = 'admin_only',
  CONTRIBUTORS = 'contributors',
  KNOWN_CONTRIBUTORS = 'known_contributors',
  MODERATORS = 'moderators',
  PUBLIC = 'public',
  TRUSTED_CONTRIBUTORS = 'trusted_contributors'
}

export type PostSubjectNotesVisibilityTypeNullableFilter = {
  equals?: InputMaybe<PostSubjectNotesVisibilityType>;
  in?: InputMaybe<Array<PostSubjectNotesVisibilityType>>;
  not?: InputMaybe<PostSubjectNotesVisibilityTypeNullableFilter>;
  notIn?: InputMaybe<Array<PostSubjectNotesVisibilityType>>;
};

export type PostSubjectOrderByInput = {
  id?: InputMaybe<OrderDirection>;
  matchStrength?: InputMaybe<OrderDirection>;
  notes?: InputMaybe<OrderDirection>;
  notesVisibility?: InputMaybe<OrderDirection>;
  visibility?: InputMaybe<OrderDirection>;
};

export type PostSubjectRelateToManyForCreateInput = {
  connect?: InputMaybe<Array<PostSubjectWhereUniqueInput>>;
  create?: InputMaybe<Array<PostSubjectCreateInput>>;
};

export type PostSubjectRelateToManyForUpdateInput = {
  connect?: InputMaybe<Array<PostSubjectWhereUniqueInput>>;
  create?: InputMaybe<Array<PostSubjectCreateInput>>;
  disconnect?: InputMaybe<Array<PostSubjectWhereUniqueInput>>;
  set?: InputMaybe<Array<PostSubjectWhereUniqueInput>>;
};

export type PostSubjectUpdateArgs = {
  data: PostSubjectUpdateInput;
  where: PostSubjectWhereUniqueInput;
};

export type PostSubjectUpdateInput = {
  matchStrength?: InputMaybe<PostSubjectMatchStrengthType>;
  notes?: InputMaybe<Scalars['String']>;
  notesVisibility?: InputMaybe<PostSubjectNotesVisibilityType>;
  post?: InputMaybe<PostRelateToOneForUpdateInput>;
  subject?: InputMaybe<SubjectRelateToOneForUpdateInput>;
  visibility?: InputMaybe<PostSubjectVisibilityType>;
};

export enum PostSubjectVisibilityType {
  ADMIN_ONLY = 'admin_only',
  CONTRIBUTORS = 'contributors',
  KNOWN_CONTRIBUTORS = 'known_contributors',
  MODERATORS = 'moderators',
  PUBLIC = 'public',
  TRUSTED_CONTRIBUTORS = 'trusted_contributors'
}

export type PostSubjectVisibilityTypeNullableFilter = {
  equals?: InputMaybe<PostSubjectVisibilityType>;
  in?: InputMaybe<Array<PostSubjectVisibilityType>>;
  not?: InputMaybe<PostSubjectVisibilityTypeNullableFilter>;
  notIn?: InputMaybe<Array<PostSubjectVisibilityType>>;
};

export type PostSubjectWhereInput = {
  AND?: InputMaybe<Array<PostSubjectWhereInput>>;
  NOT?: InputMaybe<Array<PostSubjectWhereInput>>;
  OR?: InputMaybe<Array<PostSubjectWhereInput>>;
  id?: InputMaybe<IdFilter>;
  matchStrength?: InputMaybe<PostSubjectMatchStrengthTypeNullableFilter>;
  notes?: InputMaybe<StringFilter>;
  notesVisibility?: InputMaybe<PostSubjectNotesVisibilityTypeNullableFilter>;
  post?: InputMaybe<PostWhereInput>;
  subject?: InputMaybe<SubjectWhereInput>;
  visibility?: InputMaybe<PostSubjectVisibilityTypeNullableFilter>;
};

export type PostSubjectWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type PostSummary = {
  __typename?: 'PostSummary';
  content?: Maybe<PostSummary_Content_Document>;
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  post?: Maybe<Post>;
  visibility?: Maybe<PostSummaryVisibilityType>;
};

export type PostSummaryCreateInput = {
  content?: InputMaybe<Scalars['JSON']>;
  label?: InputMaybe<Scalars['String']>;
  post?: InputMaybe<PostRelateToOneForCreateInput>;
  visibility?: InputMaybe<PostSummaryVisibilityType>;
};

export type PostSummaryManyRelationFilter = {
  every?: InputMaybe<PostSummaryWhereInput>;
  none?: InputMaybe<PostSummaryWhereInput>;
  some?: InputMaybe<PostSummaryWhereInput>;
};

export type PostSummaryOrderByInput = {
  id?: InputMaybe<OrderDirection>;
  label?: InputMaybe<OrderDirection>;
  visibility?: InputMaybe<OrderDirection>;
};

export type PostSummaryRelateToManyForCreateInput = {
  connect?: InputMaybe<Array<PostSummaryWhereUniqueInput>>;
  create?: InputMaybe<Array<PostSummaryCreateInput>>;
};

export type PostSummaryRelateToManyForUpdateInput = {
  connect?: InputMaybe<Array<PostSummaryWhereUniqueInput>>;
  create?: InputMaybe<Array<PostSummaryCreateInput>>;
  disconnect?: InputMaybe<Array<PostSummaryWhereUniqueInput>>;
  set?: InputMaybe<Array<PostSummaryWhereUniqueInput>>;
};

export type PostSummaryUpdateArgs = {
  data: PostSummaryUpdateInput;
  where: PostSummaryWhereUniqueInput;
};

export type PostSummaryUpdateInput = {
  content?: InputMaybe<Scalars['JSON']>;
  label?: InputMaybe<Scalars['String']>;
  post?: InputMaybe<PostRelateToOneForUpdateInput>;
  visibility?: InputMaybe<PostSummaryVisibilityType>;
};

export enum PostSummaryVisibilityType {
  ADMIN_ONLY = 'admin_only',
  CONTRIBUTORS = 'contributors',
  KNOWN_CONTRIBUTORS = 'known_contributors',
  MODERATORS = 'moderators',
  PUBLIC = 'public',
  TRUSTED_CONTRIBUTORS = 'trusted_contributors'
}

export type PostSummaryVisibilityTypeNullableFilter = {
  equals?: InputMaybe<PostSummaryVisibilityType>;
  in?: InputMaybe<Array<PostSummaryVisibilityType>>;
  not?: InputMaybe<PostSummaryVisibilityTypeNullableFilter>;
  notIn?: InputMaybe<Array<PostSummaryVisibilityType>>;
};

export type PostSummaryWhereInput = {
  AND?: InputMaybe<Array<PostSummaryWhereInput>>;
  NOT?: InputMaybe<Array<PostSummaryWhereInput>>;
  OR?: InputMaybe<Array<PostSummaryWhereInput>>;
  id?: InputMaybe<IdFilter>;
  label?: InputMaybe<StringFilter>;
  post?: InputMaybe<PostWhereInput>;
  visibility?: InputMaybe<PostSummaryVisibilityTypeNullableFilter>;
};

export type PostSummaryWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type PostSummary_Content_Document = {
  __typename?: 'PostSummary_content_Document';
  document: Scalars['JSON'];
};


export type PostSummary_Content_DocumentDocumentArgs = {
  hydrateRelationships?: Scalars['Boolean'];
};

export type PostUpdateArgs = {
  data: PostUpdateInput;
  where: PostWhereUniqueInput;
};

export type PostUpdateInput = {
  archive?: InputMaybe<PostArchiveRelateToOneForUpdateInput>;
  author?: InputMaybe<ContactRelateToOneForUpdateInput>;
  children?: InputMaybe<PostRelateToManyForUpdateInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  impact?: InputMaybe<PostImpactRelateToManyForUpdateInput>;
  page?: InputMaybe<PageRelateToOneForUpdateInput>;
  parent?: InputMaybe<PostRelateToOneForUpdateInput>;
  platform?: InputMaybe<Scalars['String']>;
  postType?: InputMaybe<PostPostTypeType>;
  posted?: InputMaybe<Scalars['DateTime']>;
  preview?: InputMaybe<PostPreviewImageRelateToOneForUpdateInput>;
  reactions?: InputMaybe<ReactionRelateToManyForUpdateInput>;
  ref?: InputMaybe<Scalars['String']>;
  saved?: InputMaybe<PostCaptureRelateToManyForUpdateInput>;
  subjects?: InputMaybe<PostSubjectRelateToManyForUpdateInput>;
  summary?: InputMaybe<PostSummaryRelateToManyForUpdateInput>;
  url?: InputMaybe<Scalars['String']>;
  urlVisibility?: InputMaybe<PostUrlVisibilityType>;
  visibility?: InputMaybe<PostVisibilityType>;
  warnings?: InputMaybe<PostWarningRelateToManyForUpdateInput>;
};

export enum PostUrlVisibilityType {
  ADMIN_ONLY = 'admin_only',
  CONTRIBUTORS = 'contributors',
  KNOWN_CONTRIBUTORS = 'known_contributors',
  MODERATORS = 'moderators',
  PUBLIC = 'public',
  TRUSTED_CONTRIBUTORS = 'trusted_contributors'
}

export type PostUrlVisibilityTypeNullableFilter = {
  equals?: InputMaybe<PostUrlVisibilityType>;
  in?: InputMaybe<Array<PostUrlVisibilityType>>;
  not?: InputMaybe<PostUrlVisibilityTypeNullableFilter>;
  notIn?: InputMaybe<Array<PostUrlVisibilityType>>;
};

export enum PostVisibilityType {
  ADMIN_ONLY = 'admin_only',
  CONTRIBUTORS = 'contributors',
  KNOWN_CONTRIBUTORS = 'known_contributors',
  MODERATORS = 'moderators',
  PUBLIC = 'public',
  TRUSTED_CONTRIBUTORS = 'trusted_contributors'
}

export type PostVisibilityTypeNullableFilter = {
  equals?: InputMaybe<PostVisibilityType>;
  in?: InputMaybe<Array<PostVisibilityType>>;
  not?: InputMaybe<PostVisibilityTypeNullableFilter>;
  notIn?: InputMaybe<Array<PostVisibilityType>>;
};

export type PostWarning = {
  __typename?: 'PostWarning';
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  post?: Maybe<Post>;
  strength?: Maybe<PostWarningStrengthType>;
  warning?: Maybe<ContentWarning>;
};

export type PostWarningCreateInput = {
  post?: InputMaybe<PostRelateToOneForCreateInput>;
  strength?: InputMaybe<PostWarningStrengthType>;
  warning?: InputMaybe<ContentWarningRelateToOneForCreateInput>;
};

export type PostWarningManyRelationFilter = {
  every?: InputMaybe<PostWarningWhereInput>;
  none?: InputMaybe<PostWarningWhereInput>;
  some?: InputMaybe<PostWarningWhereInput>;
};

export type PostWarningOrderByInput = {
  id?: InputMaybe<OrderDirection>;
  strength?: InputMaybe<OrderDirection>;
};

export type PostWarningRelateToManyForCreateInput = {
  connect?: InputMaybe<Array<PostWarningWhereUniqueInput>>;
  create?: InputMaybe<Array<PostWarningCreateInput>>;
};

export type PostWarningRelateToManyForUpdateInput = {
  connect?: InputMaybe<Array<PostWarningWhereUniqueInput>>;
  create?: InputMaybe<Array<PostWarningCreateInput>>;
  disconnect?: InputMaybe<Array<PostWarningWhereUniqueInput>>;
  set?: InputMaybe<Array<PostWarningWhereUniqueInput>>;
};

export enum PostWarningStrengthType {
  EMPTY = 'empty',
  MODERATE = 'moderate',
  SOLID = 'solid',
  STRONG = 'strong',
  WEAK = 'weak'
}

export type PostWarningStrengthTypeNullableFilter = {
  equals?: InputMaybe<PostWarningStrengthType>;
  in?: InputMaybe<Array<PostWarningStrengthType>>;
  not?: InputMaybe<PostWarningStrengthTypeNullableFilter>;
  notIn?: InputMaybe<Array<PostWarningStrengthType>>;
};

export type PostWarningUpdateArgs = {
  data: PostWarningUpdateInput;
  where: PostWarningWhereUniqueInput;
};

export type PostWarningUpdateInput = {
  post?: InputMaybe<PostRelateToOneForUpdateInput>;
  strength?: InputMaybe<PostWarningStrengthType>;
  warning?: InputMaybe<ContentWarningRelateToOneForUpdateInput>;
};

export type PostWarningWhereInput = {
  AND?: InputMaybe<Array<PostWarningWhereInput>>;
  NOT?: InputMaybe<Array<PostWarningWhereInput>>;
  OR?: InputMaybe<Array<PostWarningWhereInput>>;
  id?: InputMaybe<IdFilter>;
  post?: InputMaybe<PostWhereInput>;
  strength?: InputMaybe<PostWarningStrengthTypeNullableFilter>;
  warning?: InputMaybe<ContentWarningWhereInput>;
};

export type PostWarningWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type PostWhereInput = {
  AND?: InputMaybe<Array<PostWhereInput>>;
  NOT?: InputMaybe<Array<PostWhereInput>>;
  OR?: InputMaybe<Array<PostWhereInput>>;
  archive?: InputMaybe<PostArchiveWhereInput>;
  author?: InputMaybe<ContactWhereInput>;
  children?: InputMaybe<PostManyRelationFilter>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<IdFilter>;
  impact?: InputMaybe<PostImpactManyRelationFilter>;
  page?: InputMaybe<PageWhereInput>;
  parent?: InputMaybe<PostWhereInput>;
  platform?: InputMaybe<StringNullableFilter>;
  postType?: InputMaybe<PostPostTypeTypeNullableFilter>;
  posted?: InputMaybe<DateTimeNullableFilter>;
  preview?: InputMaybe<PostPreviewImageWhereInput>;
  reactions?: InputMaybe<ReactionManyRelationFilter>;
  ref?: InputMaybe<StringFilter>;
  saved?: InputMaybe<PostCaptureManyRelationFilter>;
  subjects?: InputMaybe<PostSubjectManyRelationFilter>;
  summary?: InputMaybe<PostSummaryManyRelationFilter>;
  url?: InputMaybe<StringFilter>;
  urlVisibility?: InputMaybe<PostUrlVisibilityTypeNullableFilter>;
  visibility?: InputMaybe<PostVisibilityTypeNullableFilter>;
  warnings?: InputMaybe<PostWarningManyRelationFilter>;
};

export type PostWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
  ref?: InputMaybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  attachedWarning?: Maybe<AttachedWarning>;
  attachedWarnings?: Maybe<Array<AttachedWarning>>;
  attachedWarningsCount?: Maybe<Scalars['Int']>;
  authenticatedItem?: Maybe<AuthenticatedItem>;
  contact?: Maybe<Contact>;
  contactBehavior?: Maybe<ContactBehavior>;
  contactBehaviors?: Maybe<Array<ContactBehavior>>;
  contactBehaviorsCount?: Maybe<Scalars['Int']>;
  contactRelevance?: Maybe<ContactRelevance>;
  contactRelevances?: Maybe<Array<ContactRelevance>>;
  contactRelevancesCount?: Maybe<Scalars['Int']>;
  contacts?: Maybe<Array<Contact>>;
  contactsCount?: Maybe<Scalars['Int']>;
  contentWarning?: Maybe<ContentWarning>;
  contentWarnings?: Maybe<Array<ContentWarning>>;
  contentWarningsCount?: Maybe<Scalars['Int']>;
  impact?: Maybe<Impact>;
  impacts?: Maybe<Array<Impact>>;
  impactsCount?: Maybe<Scalars['Int']>;
  keystone: KeystoneMeta;
  page?: Maybe<Page>;
  pages?: Maybe<Array<Page>>;
  pagesCount?: Maybe<Scalars['Int']>;
  post?: Maybe<Post>;
  postArchive?: Maybe<PostArchive>;
  postArchiveItem?: Maybe<PostArchiveItem>;
  postArchiveItems?: Maybe<Array<PostArchiveItem>>;
  postArchiveItemsCount?: Maybe<Scalars['Int']>;
  postArchives?: Maybe<Array<PostArchive>>;
  postArchivesCount?: Maybe<Scalars['Int']>;
  postCapture?: Maybe<PostCapture>;
  postCaptures?: Maybe<Array<PostCapture>>;
  postCapturesCount?: Maybe<Scalars['Int']>;
  postImpact?: Maybe<PostImpact>;
  postImpacts?: Maybe<Array<PostImpact>>;
  postImpactsCount?: Maybe<Scalars['Int']>;
  postPreviewImage?: Maybe<PostPreviewImage>;
  postPreviewImages?: Maybe<Array<PostPreviewImage>>;
  postPreviewImagesCount?: Maybe<Scalars['Int']>;
  postSubject?: Maybe<PostSubject>;
  postSubjects?: Maybe<Array<PostSubject>>;
  postSubjectsCount?: Maybe<Scalars['Int']>;
  postSummaries?: Maybe<Array<PostSummary>>;
  postSummariesCount?: Maybe<Scalars['Int']>;
  postSummary?: Maybe<PostSummary>;
  postWarning?: Maybe<PostWarning>;
  postWarnings?: Maybe<Array<PostWarning>>;
  postWarningsCount?: Maybe<Scalars['Int']>;
  posts?: Maybe<Array<Post>>;
  postsCount?: Maybe<Scalars['Int']>;
  reaction?: Maybe<Reaction>;
  reactions?: Maybe<Array<Reaction>>;
  reactionsCount?: Maybe<Scalars['Int']>;
  subject?: Maybe<Subject>;
  subjects?: Maybe<Array<Subject>>;
  subjectsCount?: Maybe<Scalars['Int']>;
  /** Return the total number of posts in the system (total number, not limited to just transphobic content) */
  totalPostCount: Scalars['Int'];
  /** Return the total number of profiles in the system (total number, not limited to just transphobes) */
  totalProfileCount: Scalars['Int'];
  /** The total number of reactions in the system (total number, not limited to just transphobic content) */
  totalReactionCount: Scalars['Int'];
  user?: Maybe<User>;
  users?: Maybe<Array<User>>;
  usersCount?: Maybe<Scalars['Int']>;
};


export type QueryAttachedWarningArgs = {
  where: AttachedWarningWhereUniqueInput;
};


export type QueryAttachedWarningsArgs = {
  orderBy?: Array<AttachedWarningOrderByInput>;
  skip?: Scalars['Int'];
  take?: InputMaybe<Scalars['Int']>;
  where?: AttachedWarningWhereInput;
};


export type QueryAttachedWarningsCountArgs = {
  where?: AttachedWarningWhereInput;
};


export type QueryContactArgs = {
  where: ContactWhereUniqueInput;
};


export type QueryContactBehaviorArgs = {
  where: ContactBehaviorWhereUniqueInput;
};


export type QueryContactBehaviorsArgs = {
  orderBy?: Array<ContactBehaviorOrderByInput>;
  skip?: Scalars['Int'];
  take?: InputMaybe<Scalars['Int']>;
  where?: ContactBehaviorWhereInput;
};


export type QueryContactBehaviorsCountArgs = {
  where?: ContactBehaviorWhereInput;
};


export type QueryContactRelevanceArgs = {
  where: ContactRelevanceWhereUniqueInput;
};


export type QueryContactRelevancesArgs = {
  orderBy?: Array<ContactRelevanceOrderByInput>;
  skip?: Scalars['Int'];
  take?: InputMaybe<Scalars['Int']>;
  where?: ContactRelevanceWhereInput;
};


export type QueryContactRelevancesCountArgs = {
  where?: ContactRelevanceWhereInput;
};


export type QueryContactsArgs = {
  orderBy?: Array<ContactOrderByInput>;
  skip?: Scalars['Int'];
  take?: InputMaybe<Scalars['Int']>;
  where?: ContactWhereInput;
};


export type QueryContactsCountArgs = {
  where?: ContactWhereInput;
};


export type QueryContentWarningArgs = {
  where: ContentWarningWhereUniqueInput;
};


export type QueryContentWarningsArgs = {
  orderBy?: Array<ContentWarningOrderByInput>;
  skip?: Scalars['Int'];
  take?: InputMaybe<Scalars['Int']>;
  where?: ContentWarningWhereInput;
};


export type QueryContentWarningsCountArgs = {
  where?: ContentWarningWhereInput;
};


export type QueryImpactArgs = {
  where: ImpactWhereUniqueInput;
};


export type QueryImpactsArgs = {
  orderBy?: Array<ImpactOrderByInput>;
  skip?: Scalars['Int'];
  take?: InputMaybe<Scalars['Int']>;
  where?: ImpactWhereInput;
};


export type QueryImpactsCountArgs = {
  where?: ImpactWhereInput;
};


export type QueryPageArgs = {
  where: PageWhereUniqueInput;
};


export type QueryPagesArgs = {
  orderBy?: Array<PageOrderByInput>;
  skip?: Scalars['Int'];
  take?: InputMaybe<Scalars['Int']>;
  where?: PageWhereInput;
};


export type QueryPagesCountArgs = {
  where?: PageWhereInput;
};


export type QueryPostArgs = {
  where: PostWhereUniqueInput;
};


export type QueryPostArchiveArgs = {
  where: PostArchiveWhereUniqueInput;
};


export type QueryPostArchiveItemArgs = {
  where: PostArchiveItemWhereUniqueInput;
};


export type QueryPostArchiveItemsArgs = {
  orderBy?: Array<PostArchiveItemOrderByInput>;
  skip?: Scalars['Int'];
  take?: InputMaybe<Scalars['Int']>;
  where?: PostArchiveItemWhereInput;
};


export type QueryPostArchiveItemsCountArgs = {
  where?: PostArchiveItemWhereInput;
};


export type QueryPostArchivesArgs = {
  orderBy?: Array<PostArchiveOrderByInput>;
  skip?: Scalars['Int'];
  take?: InputMaybe<Scalars['Int']>;
  where?: PostArchiveWhereInput;
};


export type QueryPostArchivesCountArgs = {
  where?: PostArchiveWhereInput;
};


export type QueryPostCaptureArgs = {
  where: PostCaptureWhereUniqueInput;
};


export type QueryPostCapturesArgs = {
  orderBy?: Array<PostCaptureOrderByInput>;
  skip?: Scalars['Int'];
  take?: InputMaybe<Scalars['Int']>;
  where?: PostCaptureWhereInput;
};


export type QueryPostCapturesCountArgs = {
  where?: PostCaptureWhereInput;
};


export type QueryPostImpactArgs = {
  where: PostImpactWhereUniqueInput;
};


export type QueryPostImpactsArgs = {
  orderBy?: Array<PostImpactOrderByInput>;
  skip?: Scalars['Int'];
  take?: InputMaybe<Scalars['Int']>;
  where?: PostImpactWhereInput;
};


export type QueryPostImpactsCountArgs = {
  where?: PostImpactWhereInput;
};


export type QueryPostPreviewImageArgs = {
  where: PostPreviewImageWhereUniqueInput;
};


export type QueryPostPreviewImagesArgs = {
  orderBy?: Array<PostPreviewImageOrderByInput>;
  skip?: Scalars['Int'];
  take?: InputMaybe<Scalars['Int']>;
  where?: PostPreviewImageWhereInput;
};


export type QueryPostPreviewImagesCountArgs = {
  where?: PostPreviewImageWhereInput;
};


export type QueryPostSubjectArgs = {
  where: PostSubjectWhereUniqueInput;
};


export type QueryPostSubjectsArgs = {
  orderBy?: Array<PostSubjectOrderByInput>;
  skip?: Scalars['Int'];
  take?: InputMaybe<Scalars['Int']>;
  where?: PostSubjectWhereInput;
};


export type QueryPostSubjectsCountArgs = {
  where?: PostSubjectWhereInput;
};


export type QueryPostSummariesArgs = {
  orderBy?: Array<PostSummaryOrderByInput>;
  skip?: Scalars['Int'];
  take?: InputMaybe<Scalars['Int']>;
  where?: PostSummaryWhereInput;
};


export type QueryPostSummariesCountArgs = {
  where?: PostSummaryWhereInput;
};


export type QueryPostSummaryArgs = {
  where: PostSummaryWhereUniqueInput;
};


export type QueryPostWarningArgs = {
  where: PostWarningWhereUniqueInput;
};


export type QueryPostWarningsArgs = {
  orderBy?: Array<PostWarningOrderByInput>;
  skip?: Scalars['Int'];
  take?: InputMaybe<Scalars['Int']>;
  where?: PostWarningWhereInput;
};


export type QueryPostWarningsCountArgs = {
  where?: PostWarningWhereInput;
};


export type QueryPostsArgs = {
  orderBy?: Array<PostOrderByInput>;
  skip?: Scalars['Int'];
  take?: InputMaybe<Scalars['Int']>;
  where?: PostWhereInput;
};


export type QueryPostsCountArgs = {
  where?: PostWhereInput;
};


export type QueryReactionArgs = {
  where: ReactionWhereUniqueInput;
};


export type QueryReactionsArgs = {
  orderBy?: Array<ReactionOrderByInput>;
  skip?: Scalars['Int'];
  take?: InputMaybe<Scalars['Int']>;
  where?: ReactionWhereInput;
};


export type QueryReactionsCountArgs = {
  where?: ReactionWhereInput;
};


export type QuerySubjectArgs = {
  where: SubjectWhereUniqueInput;
};


export type QuerySubjectsArgs = {
  orderBy?: Array<SubjectOrderByInput>;
  skip?: Scalars['Int'];
  take?: InputMaybe<Scalars['Int']>;
  where?: SubjectWhereInput;
};


export type QuerySubjectsCountArgs = {
  where?: SubjectWhereInput;
};


export type QueryUserArgs = {
  where: UserWhereUniqueInput;
};


export type QueryUsersArgs = {
  orderBy?: Array<UserOrderByInput>;
  skip?: Scalars['Int'];
  take?: InputMaybe<Scalars['Int']>;
  where?: UserWhereInput;
};


export type QueryUsersCountArgs = {
  where?: UserWhereInput;
};

export enum QueryMode {
  DEFAULT = 'default',
  INSENSITIVE = 'insensitive'
}

export type Reaction = {
  __typename?: 'Reaction';
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  post?: Maybe<Post>;
  reaction?: Maybe<ReactionReactionType>;
  reactor?: Maybe<Contact>;
};

export type ReactionCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  post?: InputMaybe<PostRelateToOneForCreateInput>;
  reaction?: InputMaybe<ReactionReactionType>;
  reactor?: InputMaybe<ContactRelateToOneForCreateInput>;
};

export type ReactionManyRelationFilter = {
  every?: InputMaybe<ReactionWhereInput>;
  none?: InputMaybe<ReactionWhereInput>;
  some?: InputMaybe<ReactionWhereInput>;
};

export type ReactionOrderByInput = {
  createdAt?: InputMaybe<OrderDirection>;
  id?: InputMaybe<OrderDirection>;
  reaction?: InputMaybe<OrderDirection>;
};

export enum ReactionReactionType {
  ANGRY = 'angry',
  CRY = 'cry',
  HUGS = 'hugs',
  LAUGH = 'laugh',
  LIKE = 'like',
  LOVE = 'love',
  SHOCKED = 'shocked'
}

export type ReactionReactionTypeNullableFilter = {
  equals?: InputMaybe<ReactionReactionType>;
  in?: InputMaybe<Array<ReactionReactionType>>;
  not?: InputMaybe<ReactionReactionTypeNullableFilter>;
  notIn?: InputMaybe<Array<ReactionReactionType>>;
};

export type ReactionRelateToManyForCreateInput = {
  connect?: InputMaybe<Array<ReactionWhereUniqueInput>>;
  create?: InputMaybe<Array<ReactionCreateInput>>;
};

export type ReactionRelateToManyForUpdateInput = {
  connect?: InputMaybe<Array<ReactionWhereUniqueInput>>;
  create?: InputMaybe<Array<ReactionCreateInput>>;
  disconnect?: InputMaybe<Array<ReactionWhereUniqueInput>>;
  set?: InputMaybe<Array<ReactionWhereUniqueInput>>;
};

export type ReactionUpdateArgs = {
  data: ReactionUpdateInput;
  where: ReactionWhereUniqueInput;
};

export type ReactionUpdateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  post?: InputMaybe<PostRelateToOneForUpdateInput>;
  reaction?: InputMaybe<ReactionReactionType>;
  reactor?: InputMaybe<ContactRelateToOneForUpdateInput>;
};

export type ReactionWhereInput = {
  AND?: InputMaybe<Array<ReactionWhereInput>>;
  NOT?: InputMaybe<Array<ReactionWhereInput>>;
  OR?: InputMaybe<Array<ReactionWhereInput>>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<IdFilter>;
  post?: InputMaybe<PostWhereInput>;
  reaction?: InputMaybe<ReactionReactionTypeNullableFilter>;
  reactor?: InputMaybe<ContactWhereInput>;
};

export type ReactionWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type StringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type StringNullableFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type Subject = {
  __typename?: 'Subject';
  children?: Maybe<Array<Subject>>;
  childrenCount?: Maybe<Scalars['Int']>;
  fullIdx?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  idx?: Maybe<Scalars['Int']>;
  label?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  parent?: Maybe<Subject>;
};


export type SubjectChildrenArgs = {
  orderBy?: Array<SubjectOrderByInput>;
  skip?: Scalars['Int'];
  take?: InputMaybe<Scalars['Int']>;
  where?: SubjectWhereInput;
};


export type SubjectChildrenCountArgs = {
  where?: SubjectWhereInput;
};

export type SubjectCreateInput = {
  children?: InputMaybe<SubjectRelateToManyForCreateInput>;
  fullIdx?: InputMaybe<Scalars['String']>;
  idx?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  parent?: InputMaybe<SubjectRelateToOneForCreateInput>;
};

export type SubjectManyRelationFilter = {
  every?: InputMaybe<SubjectWhereInput>;
  none?: InputMaybe<SubjectWhereInput>;
  some?: InputMaybe<SubjectWhereInput>;
};

export type SubjectOrderByInput = {
  fullIdx?: InputMaybe<OrderDirection>;
  id?: InputMaybe<OrderDirection>;
  idx?: InputMaybe<OrderDirection>;
  name?: InputMaybe<OrderDirection>;
};

export type SubjectRelateToManyForCreateInput = {
  connect?: InputMaybe<Array<SubjectWhereUniqueInput>>;
  create?: InputMaybe<Array<SubjectCreateInput>>;
};

export type SubjectRelateToManyForUpdateInput = {
  connect?: InputMaybe<Array<SubjectWhereUniqueInput>>;
  create?: InputMaybe<Array<SubjectCreateInput>>;
  disconnect?: InputMaybe<Array<SubjectWhereUniqueInput>>;
  set?: InputMaybe<Array<SubjectWhereUniqueInput>>;
};

export type SubjectRelateToOneForCreateInput = {
  connect?: InputMaybe<SubjectWhereUniqueInput>;
  create?: InputMaybe<SubjectCreateInput>;
};

export type SubjectRelateToOneForUpdateInput = {
  connect?: InputMaybe<SubjectWhereUniqueInput>;
  create?: InputMaybe<SubjectCreateInput>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
};

export type SubjectUpdateArgs = {
  data: SubjectUpdateInput;
  where: SubjectWhereUniqueInput;
};

export type SubjectUpdateInput = {
  children?: InputMaybe<SubjectRelateToManyForUpdateInput>;
  fullIdx?: InputMaybe<Scalars['String']>;
  idx?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  parent?: InputMaybe<SubjectRelateToOneForUpdateInput>;
};

export type SubjectWhereInput = {
  AND?: InputMaybe<Array<SubjectWhereInput>>;
  NOT?: InputMaybe<Array<SubjectWhereInput>>;
  OR?: InputMaybe<Array<SubjectWhereInput>>;
  children?: InputMaybe<SubjectManyRelationFilter>;
  fullIdx?: InputMaybe<StringFilter>;
  id?: InputMaybe<IdFilter>;
  idx?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  parent?: InputMaybe<SubjectWhereInput>;
};

export type SubjectWhereUniqueInput = {
  fullIdx?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
};

export type User = {
  __typename?: 'User';
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isAdmin?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  password?: Maybe<PasswordState>;
  role?: Maybe<UserRoleType>;
};

export type UserAuthenticationWithPasswordFailure = {
  __typename?: 'UserAuthenticationWithPasswordFailure';
  message: Scalars['String'];
};

export type UserAuthenticationWithPasswordResult = UserAuthenticationWithPasswordFailure | UserAuthenticationWithPasswordSuccess;

export type UserAuthenticationWithPasswordSuccess = {
  __typename?: 'UserAuthenticationWithPasswordSuccess';
  item: User;
  sessionToken: Scalars['String'];
};

export type UserCreateInput = {
  email?: InputMaybe<Scalars['String']>;
  isAdmin?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<UserRoleType>;
};

export type UserOrderByInput = {
  email?: InputMaybe<OrderDirection>;
  id?: InputMaybe<OrderDirection>;
  isAdmin?: InputMaybe<OrderDirection>;
  name?: InputMaybe<OrderDirection>;
  role?: InputMaybe<OrderDirection>;
};

export enum UserRoleType {
  ADMIN_ONLY = 'admin_only',
  CONTRIBUTORS = 'contributors',
  KNOWN_CONTRIBUTORS = 'known_contributors',
  MODERATORS = 'moderators',
  TRUSTED_CONTRIBUTORS = 'trusted_contributors'
}

export type UserRoleTypeNullableFilter = {
  equals?: InputMaybe<UserRoleType>;
  in?: InputMaybe<Array<UserRoleType>>;
  not?: InputMaybe<UserRoleTypeNullableFilter>;
  notIn?: InputMaybe<Array<UserRoleType>>;
};

export type UserUpdateArgs = {
  data: UserUpdateInput;
  where: UserWhereUniqueInput;
};

export type UserUpdateInput = {
  email?: InputMaybe<Scalars['String']>;
  isAdmin?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<UserRoleType>;
};

export type UserWhereInput = {
  AND?: InputMaybe<Array<UserWhereInput>>;
  NOT?: InputMaybe<Array<UserWhereInput>>;
  OR?: InputMaybe<Array<UserWhereInput>>;
  email?: InputMaybe<StringFilter>;
  id?: InputMaybe<IdFilter>;
  isAdmin?: InputMaybe<BooleanFilter>;
  name?: InputMaybe<StringFilter>;
  role?: InputMaybe<UserRoleTypeNullableFilter>;
};

export type UserWhereUniqueInput = {
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
};

export type LoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { authenticateUserWithPassword?: Maybe<(
    { __typename?: 'UserAuthenticationWithPasswordFailure' }
    & Pick<UserAuthenticationWithPasswordFailure, 'message'>
  ) | (
    { __typename?: 'UserAuthenticationWithPasswordSuccess' }
    & Pick<UserAuthenticationWithPasswordSuccess, 'sessionToken'>
    & { item: (
      { __typename?: 'User' }
      & Pick<User, 'email'>
    ) }
  )> }
);

export type ListQueryVariables = Exact<{
  contexts: Array<ContactContextType> | ContactContextType;
  sourceContexts: Array<ContactSourceContextType> | ContactSourceContextType;
}>;


export type ListQuery = (
  { __typename?: 'Query' }
  & { contacts?: Maybe<Array<(
    { __typename?: 'Contact' }
    & Pick<Contact, 'id' | 'name' | 'publishedList' | 'contactType'>
    & { relevance?: Maybe<Array<(
      { __typename?: 'ContactRelevance' }
      & Pick<ContactRelevance, 'id' | 'description'>
    )>>, behavior?: Maybe<Array<(
      { __typename?: 'ContactBehavior' }
      & Pick<ContactBehavior, 'type' | 'profileSection' | 'summary'>
    )>> }
  )>> }
);

export type GetContactQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetContactQuery = (
  { __typename?: 'Query' }
  & { contact?: Maybe<(
    { __typename?: 'Contact' }
    & Pick<Contact, 'id' | 'name' | 'publishedList' | 'contactType'>
    & { relevance?: Maybe<Array<(
      { __typename?: 'ContactRelevance' }
      & Pick<ContactRelevance, 'id' | 'description'>
    )>>, behavior?: Maybe<Array<(
      { __typename?: 'ContactBehavior' }
      & Pick<ContactBehavior, 'id' | 'visibility' | 'type' | 'profileSection' | 'summary'>
      & { content?: Maybe<Array<(
        { __typename?: 'Post' }
        & Pick<Post, 'id' | 'postType' | 'platform'>
        & { preview?: Maybe<(
          { __typename?: 'PostPreviewImage' }
          & Pick<PostPreviewImage, 'id' | 'description'>
          & { warnings?: Maybe<Array<(
            { __typename?: 'AttachedWarning' }
            & Pick<AttachedWarning, 'id' | 'strength' | 'description'>
            & { warning?: Maybe<(
              { __typename?: 'ContentWarning' }
              & Pick<ContentWarning, 'id' | 'fullIdx' | 'name'>
            )> }
          )>>, default?: Maybe<(
            { __typename?: 'CloudImageFieldOutput' }
            & Pick<CloudImageFieldOutput, 'id' | 'filesize' | 'width' | 'height' | 'url'>
          ) | (
            { __typename?: 'LocalImageFieldOutput' }
            & Pick<LocalImageFieldOutput, 'id' | 'filesize' | 'width' | 'height' | 'url'>
          )>, nameFree?: Maybe<(
            { __typename?: 'CloudImageFieldOutput' }
            & Pick<CloudImageFieldOutput, 'id' | 'filesize' | 'width' | 'height' | 'url'>
          ) | (
            { __typename?: 'LocalImageFieldOutput' }
            & Pick<LocalImageFieldOutput, 'id' | 'filesize' | 'width' | 'height' | 'url'>
          )> }
        )>, summary?: Maybe<Array<(
          { __typename?: 'PostSummary' }
          & Pick<PostSummary, 'id' | 'label' | 'visibility'>
          & { content?: Maybe<(
            { __typename?: 'PostSummary_content_Document' }
            & Pick<PostSummary_Content_Document, 'document'>
          )> }
        )>>, subjects?: Maybe<Array<(
          { __typename?: 'PostSubject' }
          & Pick<PostSubject, 'matchStrength' | 'notes'>
          & { subject?: Maybe<(
            { __typename?: 'Subject' }
            & Pick<Subject, 'id' | 'fullIdx' | 'name'>
          )> }
        )>>, impact?: Maybe<Array<(
          { __typename?: 'PostImpact' }
          & Pick<PostImpact, 'id' | 'motive' | 'conviction' | 'impactExtent' | 'notes'>
          & { impact?: Maybe<(
            { __typename?: 'Impact' }
            & Pick<Impact, 'id' | 'fullIdx' | 'name'>
            & { abstract?: Maybe<(
              { __typename?: 'Impact' }
              & Pick<Impact, 'id' | 'fullIdx' | 'name'>
            )> }
          )> }
        )>> }
      )>> }
    )>> }
  )> }
);

export type HomepageSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type HomepageSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'totalProfileCount' | 'totalPostCount' | 'totalReactionCount'>
);

export type ListLengthQueryVariables = Exact<{
  contexts: Array<ContactContextType> | ContactContextType;
  sourceContexts: Array<ContactSourceContextType> | ContactSourceContextType;
}>;


export type ListLengthQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'contactsCount'>
);

export type GetPostQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetPostQuery = (
  { __typename?: 'Query' }
  & { post?: Maybe<(
    { __typename?: 'Post' }
    & Pick<Post, 'id' | 'postType' | 'platform' | 'posted'>
    & { author?: Maybe<(
      { __typename?: 'Contact' }
      & Pick<Contact, 'id' | 'name'>
      & { relevance?: Maybe<Array<(
        { __typename?: 'ContactRelevance' }
        & Pick<ContactRelevance, 'id' | 'description'>
      )>> }
    )>, saved?: Maybe<Array<(
      { __typename?: 'PostCapture' }
      & Pick<PostCapture, 'id' | 'description'>
      & { complete?: Maybe<(
        { __typename?: 'CloudImageFieldOutput' }
        & Pick<CloudImageFieldOutput, 'id' | 'filesize' | 'width' | 'height' | 'url'>
      ) | (
        { __typename?: 'LocalImageFieldOutput' }
        & Pick<LocalImageFieldOutput, 'id' | 'filesize' | 'width' | 'height' | 'url'>
      )>, warnings?: Maybe<Array<(
        { __typename?: 'AttachedWarning' }
        & Pick<AttachedWarning, 'id' | 'strength' | 'description'>
        & { warning?: Maybe<(
          { __typename?: 'ContentWarning' }
          & Pick<ContentWarning, 'id' | 'fullIdx' | 'name'>
        )> }
      )>>, censoredContent?: Maybe<(
        { __typename?: 'CloudImageFieldOutput' }
        & Pick<CloudImageFieldOutput, 'id' | 'filesize' | 'width' | 'height' | 'url'>
      ) | (
        { __typename?: 'LocalImageFieldOutput' }
        & Pick<LocalImageFieldOutput, 'id' | 'filesize' | 'width' | 'height' | 'url'>
      )>, censoredNames?: Maybe<(
        { __typename?: 'CloudImageFieldOutput' }
        & Pick<CloudImageFieldOutput, 'id' | 'filesize' | 'width' | 'height' | 'url'>
      ) | (
        { __typename?: 'LocalImageFieldOutput' }
        & Pick<LocalImageFieldOutput, 'id' | 'filesize' | 'width' | 'height' | 'url'>
      )>, censoredNamesAndContent?: Maybe<(
        { __typename?: 'CloudImageFieldOutput' }
        & Pick<CloudImageFieldOutput, 'id' | 'filesize' | 'width' | 'height' | 'url'>
      ) | (
        { __typename?: 'LocalImageFieldOutput' }
        & Pick<LocalImageFieldOutput, 'id' | 'filesize' | 'width' | 'height' | 'url'>
      )> }
    )>>, warnings?: Maybe<Array<(
      { __typename?: 'PostWarning' }
      & Pick<PostWarning, 'id' | 'strength'>
      & { warning?: Maybe<(
        { __typename?: 'ContentWarning' }
        & Pick<ContentWarning, 'id' | 'fullIdx' | 'name'>
      )> }
    )>>, impact?: Maybe<Array<(
      { __typename?: 'PostImpact' }
      & Pick<PostImpact, 'motive' | 'conviction' | 'impactExtent' | 'notes'>
      & { impact?: Maybe<(
        { __typename?: 'Impact' }
        & Pick<Impact, 'fullIdx' | 'name'>
        & { abstract?: Maybe<(
          { __typename?: 'Impact' }
          & Pick<Impact, 'fullIdx' | 'name'>
        )> }
      )> }
    )>> }
  )> }
);


export const LoginDocument = gql`
    mutation Login($email: String!, $password: String!) {
  authenticateUserWithPassword(email: $email, password: $password) {
    ... on UserAuthenticationWithPasswordSuccess {
      sessionToken
      item {
        email
      }
    }
    ... on UserAuthenticationWithPasswordFailure {
      message
    }
  }
}
    `;

export function useLoginMutation() {
  return Urql.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument);
};
export const ListDocument = gql`
    query List($contexts: [ContactContextType!]!, $sourceContexts: [ContactSourceContextType!]!) {
  contacts(
    where: {AND: [{publishedList: {notIn: [logged, likely_ally, ally, activist, public_activist]}}, {OR: [{context: {in: $contexts}}, {context: {equals: null}, sourceContext: {in: $sourceContexts}}]}]}
    orderBy: [{order: asc}]
  ) {
    id
    name
    publishedList
    contactType
    relevance {
      id
      description
    }
    behavior(where: {listedBehavior: {equals: true}}, orderBy: {order: asc}) {
      type
      profileSection
      summary
    }
  }
}
    `;

export function useListQuery(options: Omit<Urql.UseQueryArgs<ListQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<ListQuery>({ query: ListDocument, ...options });
};
export const GetContactDocument = gql`
    query GetContact($id: ID!) {
  contact(where: {id: $id}) {
    id
    name
    publishedList
    contactType
    relevance {
      id
      description
    }
    behavior(orderBy: {order: asc}) {
      id
      visibility
      type
      profileSection
      content {
        id
        preview {
          id
          warnings {
            id
            warning {
              id
              fullIdx
              name
            }
            strength
            description
          }
          default {
            id
            filesize
            width
            height
            url
          }
          nameFree {
            id
            filesize
            width
            height
            url
          }
          description
        }
        summary {
          id
          label
          visibility
          content {
            document(hydrateRelationships: true)
          }
        }
        postType
        platform
        subjects {
          subject {
            id
            fullIdx
            name
          }
          matchStrength
          notes
        }
        impact {
          id
          impact {
            id
            abstract {
              id
              fullIdx
              name
            }
            fullIdx
            name
          }
          motive
          conviction
          impactExtent
          notes
        }
      }
      summary
    }
  }
}
    `;

export function useGetContactQuery(options: Omit<Urql.UseQueryArgs<GetContactQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GetContactQuery>({ query: GetContactDocument, ...options });
};
export const HomepageSummaryDocument = gql`
    query HomepageSummary {
  totalProfileCount
  totalPostCount
  totalReactionCount
}
    `;

export function useHomepageSummaryQuery(options: Omit<Urql.UseQueryArgs<HomepageSummaryQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<HomepageSummaryQuery>({ query: HomepageSummaryDocument, ...options });
};
export const ListLengthDocument = gql`
    query ListLength($contexts: [ContactContextType!]!, $sourceContexts: [ContactSourceContextType!]!) {
  contactsCount(
    where: {AND: [{publishedList: {notIn: [logged, likely_ally, ally, activist, public_activist]}}, {OR: [{context: {in: $contexts}}, {context: {equals: null}, sourceContext: {in: $sourceContexts}}]}]}
  )
}
    `;

export function useListLengthQuery(options: Omit<Urql.UseQueryArgs<ListLengthQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<ListLengthQuery>({ query: ListLengthDocument, ...options });
};
export const GetPostDocument = gql`
    query GetPost($id: ID!) {
  post(where: {id: $id}) {
    id
    author {
      id
      name
      relevance {
        id
        description
      }
    }
    postType
    platform
    posted
    saved {
      id
      complete {
        id
        filesize
        width
        height
        url
      }
      description
      warnings {
        id
        warning {
          id
          fullIdx
          name
        }
        strength
        description
      }
      censoredContent {
        id
        filesize
        width
        height
        url
      }
      censoredNames {
        id
        filesize
        width
        height
        url
      }
      censoredNamesAndContent {
        id
        filesize
        width
        height
        url
      }
    }
    warnings {
      id
      warning {
        id
        fullIdx
        name
      }
      strength
    }
    impact {
      impact {
        abstract {
          fullIdx
          name
        }
        fullIdx
        name
      }
      motive
      conviction
      impactExtent
      notes
    }
  }
}
    `;

export function useGetPostQuery(options: Omit<Urql.UseQueryArgs<GetPostQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GetPostQuery>({ query: GetPostDocument, ...options });
};