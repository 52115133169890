import {
  Box,
  Container,
  SimpleGrid,
  Skeleton,
  Stat,
  StatGroup,
  StatLabel,
  StatNumber,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";
// import { Link as RouterLink } from "react-router-dom";
import { useHomepageSummaryQuery } from "../generated/graphql";

export const DataBasis = () => {
  const bg = useColorModeValue("gray.300", "gray.700");
  const [{ fetching, data, error }] = useHomepageSummaryQuery();
  return (
    <Container maxW="container.md">
      <Text align="left">based on</Text>
      <Box bg={bg} borderRadius="sm" p={3} mt={1}>
        <StatGroup>
          <Stat>
            <StatLabel>Profiles</StatLabel>
            <StatNumber>
              {
                fetching
                  ? "-"
                  : (error || !data)
                    ? "!"
                    : data.totalProfileCount
              }
            </StatNumber>
          </Stat>
          <Stat>
            <StatLabel>Posts</StatLabel>
            <StatNumber>
              {
                fetching
                  ? "-"
                  : (error || !data)
                    ? "!"
                    : data.totalPostCount
              }
            </StatNumber>
          </Stat>
          <Stat>
            <StatLabel>Reactions/Interactions</StatLabel>
            <StatNumber>
              {
                fetching
                  ? <Skeleton width="2em" />
                  : (error || !data)
                    ? "!"
                    : data.totalReactionCount
              }
            </StatNumber>
          </Stat>
        </StatGroup>
        <SimpleGrid columns={2} mt={4}>
          <Box>
            <Text
              fontSize="md"
              align="left"
              color="gray.500"
            >
              ...and counting
            </Text>
          </Box>
          <Box>
            <Text fontSize="md" align="right">
              { /* <Link as={RouterLink} to="/report/">Report Content</Link> */ }
            </Text>
          </Box>
        </SimpleGrid>
      </Box>
    </Container>
  );
};
